/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2018-09-12 18:52:37
 * @modify date 2018-09-12 18:52:37
 * @desc [description]
 */
import lodash from 'lodash'
import { Observable, of, TimeoutError, interval } from 'rxjs'
import { ajax, AjaxError, AjaxResponse, AjaxRequest } from 'rxjs/ajax'
import { catchError, filter, map, merge, timeout } from 'rxjs/operators'
import queryString from 'query-string'
import { Regulars } from './regulars'
import moment from 'moment'
import { getFp } from '@xt/client/utils/fingerprint/report'
import $global from '@xt/client/store/global'
import { getXTDevId } from '@xt/client/utils/uuid'
import { IAjaxBasicsOptions, formatApi, formatApiSnapshot } from './formatApiSnapshot'
import { ArmsSumKeys } from '@xt/client/enums'

export class AjaxBasics {
  /**
   *
   * @param target
   */
  constructor(options?: IAjaxBasicsOptions) {
    this.options = lodash.assign(
      {
        target: '/',
        timeout: 900000
      },
      options
    )
    this.onTime()
  }
  static windowHideTime: number
  static oldServiceDate: number
  static serviceDate = moment(Date.now())
  static serviceDateKey = 'Date'
  /**
   * 服务器时间
   * @readonly
   * @memberof AjaxBasics
   */
  get serviceDate() {
    return AjaxBasics.serviceDate
  }
  /**
   * 计时
   */
  onTime() {
    interval(200).subscribe(x => {
      AjaxBasics.serviceDate.add(200, 'milliseconds')
    })
    // 监听页面的显示与隐藏
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState == 'visible' && AjaxBasics.oldServiceDate && AjaxBasics.windowHideTime) {
        AjaxBasics.serviceDate = moment(AjaxBasics.oldServiceDate + Date.now() - AjaxBasics.windowHideTime)
      } else {
        AjaxBasics.windowHideTime = Date.now()
        AjaxBasics.oldServiceDate = AjaxBasics.serviceDate.valueOf()
      }
    })
  }
  options: IAjaxBasicsOptions = {}
  /** get */
  get<T>(url: string, body?: any, headers?: Object, options?: IAjaxBasicsOptions) {
    return this.request<T>({ url, body, headers, method: 'get' }, options).toPromise()
  }
  /** post */
  post<T>(url: string, body?: any, headers?: Object, options?: IAjaxBasicsOptions) {
    return this.request<T>({ url, body, headers, method: 'post' }, options).toPromise()
  }
  /** put */
  put<T>(url: string, body?: any, headers?: Object, options?: IAjaxBasicsOptions) {
    return this.request<T>({ url, body, headers, method: 'put' }, options).toPromise()
  }
  /** patch */
  patch<T>(url: string, body?: any, headers?: Object, options?: IAjaxBasicsOptions) {
    return this.request<T>({ url, body, headers, method: 'patch' }, options).toPromise()
  }
  /** delete */
  delete<T>(url: string, body?: any, headers?: Object, options?: IAjaxBasicsOptions) {
    return this.request<T>({ url, body, headers, method: 'delete' }, options).toPromise()
  }
  /** request */
  request<T>(request: string | AjaxRequest, options?: IAjaxBasicsOptions) {
    return AjaxBasics.onRequest<T>(request, lodash.assign({}, this.options, options))
  }
  /**
   * request
   * @param request
   */
  static onRequest<T>(request: string | AjaxRequest, options: IAjaxBasicsOptions) {
    if (lodash.isString(request)) {
      request = {
        url: request,
        method: 'get'
      }
    }
    return AjaxBasics.AjaxObservable<T>(request, options)
  }
  /**
   * ajax 启动
   * @param request
   */
  static async AjaxObservableStart(request: AjaxRequest) {
    // return interval(1000).toPromise()
  }

  static async initDevId(forceRefreshDevId: boolean = false) {
    // devId 跟 xtDevId含义交换
    if (!$global.devId || forceRefreshDevId) {
      $global.devId = getXTDevId($global.platform)
      AjaxBasics.headers['x-devId'] = $global.devId
    }
    if (!AjaxBasics.headers['x-xtDevId']) {
      const { current } = await getFp($global)
      AjaxBasics.headers['x-xtDevId'] = current
    }
  }

  /**
   * ajax Observable 管道
   * @param Observable
   */
  static AjaxObservable<T>(request: AjaxRequest, options: IAjaxBasicsOptions) {
    return new Observable<T>((async sub => {
      await AjaxBasics.initDevId()
      await AjaxBasics.AjaxObservableStart(request)
      request = AjaxBasics.onCompatibleAjaxRequest(request, options)
      // if(JSON.stringify(request) == httpRequestRepeat){
      //     return false;
      // }
      // httpRequestRepeat = JSON.stringify(request);

      const start = Date.now()

      // 校验path参数异常
      if (request.url && window.__bl) {
        const _url = new URL(request.url, $global.target)
        const _origin = _url.origin
        const _pathname = _url.pathname
        // 只支持本域内的检测
        if ($global.target === _origin && (_pathname.endsWith('/') || _pathname.includes('//'))) {
          window.__bl.api(request.url, false, Date.now() - start, -1, 'path参数异常', start)
          window.__bl.sum(ArmsSumKeys.PATH_PARAMTER_LOSS)
          sub.error('path参数异常')
          return
        }
      }

      ajax(request)
        .pipe(
          // 超时时间
          timeout(options.timeout),
          // 错误处理
          catchError(err => of(err)),
          // 过滤请求
          filter(ajax => {
            const end = Date.now()
            try {
              const trace = request.url.includes('/mocks/') ? '' : ajax.xhr.getResponseHeader('x-xt-trace-id')
              const success = ajax.xhr.status >= 200 && ajax.xhr.status <= 299
              const snapshot = formatApiSnapshot({
                ajax: ajax.xhr,
                method: request.method,
                data: request.body,
                headers: request.headers,
                sensitiveDataFields: ['password', 'newPassword', 'confirmNewPassword'],
                pickRequestHeaders: ['x-xt-client-info'],
                pickResponseHeaders: ['date', 'x-xt-trace-id']
              })
              // console.warn('请求', request.url.replace($global.target, ''), ajax.xhr, end, start, end - start, trace)
              if (window.__bl) {
                window.__bl.api(
                  formatApi(request.url, ['password', 'newPassword', 'confirmNewPassword']),
                  success,
                  end - start,
                  ajax.xhr.status,
                  success ? '' : JSON.stringify(ajax.response),
                  start,
                  trace,
                  '',
                  encodeURIComponent(JSON.stringify(snapshot))
                )
              }
            } catch {}

            try {
              // 取服务器时间
              try {
                const currentTarget: XMLHttpRequest = lodash.get(ajax, 'originalEvent.currentTarget')
                if (currentTarget) {
                  let ignore = ['json', 'JSON', 'jpg', 'png', 'jpeg']
                  let url: string = request.url.split('?')[0]
                  if (ignore.indexOf(url.substring(url.lastIndexOf('.') + 1, url.length)) === -1) {
                    const time = currentTarget.getResponseHeader(AjaxBasics.serviceDateKey)
                    time && (AjaxBasics.serviceDate = moment(time))
                  }
                }
              } catch (error) {
                console.error('获取系统时间错误')
              }
              return AjaxBasics.onFilter(ajax)
            } catch (error) {
              AjaxBasics.onError(error)
              sub.error(error)
              return false
            }
          }),
          // 数据过滤
          map(res => AjaxBasics.onMap(res))
        )
        .subscribe(res => {
          // setTimeout(()=>{ httpRequestRepeat = "" },300);
          sub.next(res)
          sub.complete()
        })
    }) as any)
  }

  /**
   * 请求头
   * @type {*}
   * @memberof Request
   */
  static headers: any = {
    'Content-Type': 'application/json'
  }
  /**
   * 请求 map 转换
   * @param res
   */
  static onMap(res: AjaxResponse) {
    switch (res.status) {
      case 200:
        // 流
        if (lodash.toLower(res.responseType) === 'blob') {
          return res
        }
        return res.response
    }
    return res
  }
  /**
   * 请求过滤
   *
   * @static
   * @param {(AjaxResponse | AjaxError | TimeoutError)} ajax
   * @returns {boolean}
   * @memberof AjaxBasics
   */
  static onFilter(ajax: AjaxResponse | AjaxError | TimeoutError): boolean {
    // 数据 Response
    if (ajax instanceof AjaxResponse) {
      // 无 响应 数据
      if (lodash.isNil(ajax.response)) {
        console.error('ajax response undefined', lodash.assign(ajax, { message: 'ajax response undefined' }))
        // throw lodash.merge(ajax, { message: 'ajax response undefined' })
      }
      // else if (!lodash.eq(lodash.get(ajax.response, 'Code', 200), 200)) {
      //     throw lodash.merge(ajax, { message: lodash.get(ajax.response, 'Msg') })
      // }
    }
    // 错误 超时
    else if (ajax instanceof AjaxError || ajax instanceof TimeoutError) {
      throw ajax
    }
    return true
  }
  /**
   * 错误处理
   * @static
   * @param {*} error
   * @memberof AjaxBasics
   */
  static onError(error) {
    console.error('LENG: AjaxBasics -> onError -> error', error)
  }
  /**
   * 处理 AjaxRequest
   * @static
   * @param {(string | AjaxRequest)} request
   * @param {IAjaxBasicsOptions} options
   * @returns
   * @memberof AjaxBasics
   */
  static onCompatibleAjaxRequest(request: AjaxRequest, options: IAjaxBasicsOptions) {
    request = lodash.cloneDeep(request)
    request = AjaxBasics.onCompatibleHeaders(request)
    request = AjaxBasics.onCompatibleBody(request)
    request = AjaxBasics.onCompatibleUrl(request, options)
    request = AjaxBasics.onCompatibleCreateXHR(request, options)
    return request
  }
  /**
   * 处理 Headers
   *
   * @static
   * @param {AjaxRequest} request
   * @returns
   * @memberof AjaxBasics
   */
  static onCompatibleHeaders(request: AjaxRequest) {
    const headers = lodash.assign({}, AjaxBasics.headers, AjaxBasics.onMergeHeaders({}, request), request.headers)

    // 处理公共请求头
    const baseHeaders = []
    Object.keys(headers).forEach(key => {
      if (key.startsWith('x-')) {
        baseHeaders.push(`${key.substring(2)}=${headers[key]}`)
        delete headers[key]
      }
    })

    request.headers = {
      ...headers,
      'x-xt-client-info': baseHeaders.join(';')
    }
    return request
  }
  /**
   * 处理 Body
   * @static
   * @param {AjaxRequest} request
   * @returns
   * @memberof AjaxBasics
   */
  static onCompatibleBody(request: AjaxRequest) {
    let { headers, body } = request
    switch (lodash.get(headers, 'Content-Type')) {
      case 'application/json;charset=UTF-8':
        body = JSON.stringify(body)
        break
      case 'application/json':
        break
      case 'application/x-www-form-urlencoded':
        break
      case 'form-data':
      case 'multipart/form-data':
        break
      case null:
      case undefined:
        // delete headers["Content-Type"];
        lodash.unset(headers, 'Content-Type')
        body = lodash.assign(body, AjaxBasics.onMergeBody())
        const formData = new FormData()
        lodash.mapValues(body, (value, key) => {
          formData.append(key, value)
        })
        request.body = formData
        return request
      default:
        break
    }
    request.body = lodash.assign(body, AjaxBasics.onMergeBody())
    return request
  }
  /**
   * 合并 body
   * @param body
   */
  static onMergeBody(body: any = {}) {
    return body
  }
  /**
   * 合并 Headers
   * @param body
   */
  static onMergeHeaders(Headers: any = {}, request: AjaxRequest) {
    return Headers
  }
  /**
   * 处理 Url
   * @static
   * @param {AjaxRequest} request
   * @param {IAjaxBasicsOptions} options
   * @returns
   * @memberof AjaxBasics
   */
  static onCompatibleUrl(request: AjaxRequest, options: IAjaxBasicsOptions) {
    try {
      const method = lodash.toLower(request.method || 'get')
      if (lodash.isObject(request.body)) {
        const interpolate = /{([\s\S]+?)}/g
        // 处理 url  参数 /test/{id}
        if (interpolate.test(request.url)) {
          try {
            request.url = lodash.template(request.url, { interpolate })(request.body)
          } catch (error) {
            lodash.uniq(request.url.match(interpolate)).map(par => {
              const key = lodash.trimEnd(lodash.trimStart(par, '{'), '}')
              const reg = new RegExp(`(\\${par})`, 'g')
              request.url = lodash.replace(request.url, reg, lodash.get(request.body, key, ''))
            })
          }
          // 清理 body
          if (lodash.get(request.body, 'delete', false) === true) {
            request.body = undefined
          }
        }
      }
      if (lodash.eq(method, 'get')) {
        request.url = queryString.stringifyUrl({
          url: String(request.url),
          query: lodash.pickBy(request.body, lodash.identity)
        })
      }
      const isEnd = lodash.endsWith(options.target, '/')
      const isStart = lodash.startsWith(request.url, '/')
      if (isEnd && isStart) {
        request.url = lodash.trimStart(request.url, '/')
      }
      request.url = Regulars.url.test(request.url) ? request.url : AjaxBasics.disposeStatucUrl(request.url, options.target)
    } catch (error) {
      console.warn(error)
    }
    return request
  }

  static disposeStatucUrl(url: string, target: string) {
    if (target.indexOf('testing-static.') !== -1 && lodash.get(window, '__xt__env.NODE_ENV', process.env.NODE_ENV) === 'development')
      return '/devStaticTarget' + url
    return target + url
  }
  /**
   * 重写 XHR
   * @static
   * @param {AjaxRequest} request
   * @param {IAjaxBasicsOptions} options
   * @returns
   * @memberof AjaxBasics
   */
  static onCompatibleCreateXHR(request: AjaxRequest, options: IAjaxBasicsOptions) {
    if (lodash.hasIn(options, 'createXHR')) {
      request.createXHR = options.createXHR
    }
    return request
  }
}
// export default new AjaxBasics();
