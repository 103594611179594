



















import { EnumProductTypeQuery } from '@xt/client/enums'
import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

export const Progressing = 'progressing'

@Component
export default class PageView extends Vue {
  @Prop({}) offsetTop: number
  tabs = Object.freeze([
    {
      id: 1,
      title: '学习中',
      type: Progressing
    },
    {
      id: 2,
      title: '直播课',
      type: EnumProductTypeQuery.Course
    },
    {
      id: 3,
      title: '教练日课',
      type: EnumProductTypeQuery.DayCourse
    },
    {
      id: 4,
      title: '展览辅导课',
      type: EnumProductTypeQuery.Exhibition
    },
    {
      id: 5,
      title: '自习课',
      type: EnumProductTypeQuery.SelfStudy
    }
  ])
  activeItem = this.tabs[0]
  isSticky = false
  isSmoothMove = false
  fixBottomOffset = 6 + 298 + 46

  @Ref('study-tab')
  studyTab: HTMLDivElement

  get EnumProductTypeQuery() {
    return EnumProductTypeQuery
  }

  get Progressing() {
    return Progressing
  }

  @Emit('change')
  changeTabIndex(item: any) {
    if (item.id === this.activeItem.id) return

    this.activeItem = item

    this.isSticky = false
    this.isSmoothMove = true
    this.studyTab.style.removeProperty('top')
    window.scrollTo({ top: 0, behavior: 'smooth' })

    requestAnimationFrame(() => this.checkReachTarget(0))
    return this.activeItem
  }

  checkReachTarget(target: number) {
    if (window.scrollY === target) {
      this.isSmoothMove = false
    } else {
      requestAnimationFrame(() => this.checkReachTarget(target))
    }
  }

  onScroll() {
    if (this.offsetTop || this.isSmoothMove) return
    if (window.scrollY >= this.fixBottomOffset) {
      this.isSticky = true
    } else {
      this.isSticky = false
    }
  }

  @Watch('offsetTop')
  changeOffsetTop(value: number) {
    if (value === 0) {
      this.studyTab.style.removeProperty('top')
      return
    }
    if (this.isSticky) {
      this.studyTab.style.top = this.$px2rem(170 - value)
    }
  }

  created() {
    this.$nextTick(() => {
      document.addEventListener('scroll', this.onScroll)
    })
  }
  mounted() {}
  updated() {}
  destroyed() {
    document.removeEventListener('scroll', this.onScroll)
  }
}
