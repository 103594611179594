import { render, staticRenderFns } from "./content.vue?vue&type=template&id=154d8df4&scoped=true&"
import script from "./content.vue?vue&type=script&lang=ts&"
export * from "./content.vue?vue&type=script&lang=ts&"
import style0 from "./content.vue?vue&type=style&index=0&id=154d8df4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "154d8df4",
  null
  
)

export default component.exports