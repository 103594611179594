












































































































































































































































































































































import { Component, Prop, Vue, Mixins, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import lodash from 'lodash'
import ViewCourse from './views/course.vue'
import ViewComposition from './views/composition.vue'
import VCopper from './views/copper.vue'
import { MixinsCoursePayment } from '@xt/client/mixins'
import { EnumProductType, EnumOrderStateTypeNumber } from '@xt/client/enums'
import PayPopup from '../../components/payPopup/index.vue'
import Teleport from '@xt/client/mixins/components/teleport.vue'
import HeaderImg from '../../components/headerImage/index.vue'
import LazyImg from '@/components/lazyImg/index.vue'

@Observer
@Component({
  // 每次进入页面都会调用
  async fetch(ctx) {
    const store = ctx.store.$storeCourse.Details
    // 更新用户信息 获取桐板儿
    const userInfo = ctx.store.$storeUser.onUpdatingUserInfo()
    await ctx.store.$storeUser.onGetCourseListHasbuy()
    await store.onOrderinfo(ctx.query.productId, ctx.query.give == 'true' ? false : true)
    await userInfo
    // 重新支付订单
    const orderNo = ctx.query?.orderNo
    if (orderNo) {
      await ctx.store.$storeOrder.Details.onLoading({ orderNo })
    }
  },
  validate({ query }) {
    return /^\d+$/.test(query.productId as string)
  },
  components: { ViewCourse, ViewComposition, PayPopup, Teleport, VCopper, HeaderImg, LazyImg }
})
export default class PageView extends Mixins(MixinsCoursePayment) {
  burst: boolean = false // 初始礼花🎉不迸发
  againVerifyPendingOrder: boolean = false // 再次验证是否是待支付订单

  get isRealPendingOrder() {
    return this.isAgain && this.againVerifyPendingOrder
  }

  qrCodeAliLoading() {
    console.log('正在加载请稍等')
  }

  // 微信为true  支付宝为false
  get selectPayWays() {
    return this.paymentType === 2
  }
  onOrderOvertimeCancel() {
    this.visibleOvertime = false
    this.toProductDetail()
  }
  async giftUserBlur() {
    this.receiveHasbuy = []
    await this.onBaseinfo()
    this.burst = !lodash.isEmpty(this.giftUserInfo.nickname) ? true : false
  }
  copperBlur() {
    if (!this.copper) {
      this.copper = 0
    }
    if (this.copper > this.maxCopper) {
      this.copper = this.maxCopper
    }
  }
  copperLimit(e) {
    let { value } = e.target
    this.copper = value.replace(/[^\d]/g, '')
    this.copper = value.replace(/^(0+)|[^\d]+/g, '')
    if (value.length > 8) {
      this.copper = value.substring(0, 8)
    }
  }

  get showCourseCount() {
    if (this.PageStore.Orderinfo.original.productType == EnumProductType.dayCourse) {
      return false
    }
    return true
  }

  giftUserUpdate(e) {
    let value = e.target.value
    if (value.length > 30) return (this.giftUser = value.substring(0, 30))
    this.onBaseinfo()
  }

  get shouldPrice() {
    if (this.$route.query.group == 'true') {
      return this.PageStore.Orderinfo?.original?.groupPrice
    }
    return this.PageStore.Orderinfo.discountPrice
  }
  async isCompletePayment() {
    const { orderNo } = this.$route.query
    let res = await this.PaymentStore.Details.onLoading({ orderNo })
    if (res?.orderStatus === EnumOrderStateTypeNumber.NotPay) {
      // 如果是待支付订单，弹出Toast提示
      this.$msg('您还没有支付该订单哟~')
    } else if (res?.orderStatus === EnumOrderStateTypeNumber.HasPay) {
      this.onSuccess(orderNo)
    } else {
      const query = lodash.clone(this.$route.query)
      lodash.unset(query, 'orderNo')
      lodash.unset(query, 'memberBeforeOption')
      await this.$router.replace({ query })
      this.onDownTimerVisible(false)
      this.againVerifyPendingOrder = false
      this.toProductDetail()
    }
  }
  async created() {
    let orderNo = this.orderNo ? this.orderNo : ''
    if (orderNo) {
      let orderDetail = await this.PaymentStore.Details.onLoading({ orderNo })
      if (orderDetail?.orderStatus === EnumOrderStateTypeNumber.NotPay) {
        this.onDownTimerVisible(true)
        this.againVerifyPendingOrder = true
      } else {
        const query = lodash.clone(this.$route.query)
        lodash.unset(query, 'orderNo')
        lodash.unset(query, 'memberBeforeOption')
        await this.$router.replace({ query })
        this.onDownTimerVisible(false)
        this.againVerifyPendingOrder = false
        this.toProductDetail()
      }
    }
    await this.onInit()
    this.burst = !lodash.isEmpty(this.giftUserInfo.nickname) ? true : false
  }
  ob: IntersectionObserver | null = null
  showFloatWindow: boolean = true
  mounted() {
    this.$nextTick(() => {
      const paymentBtn = document.querySelector('.pay-price')
      this.ob = new IntersectionObserver(
        entries => {
          entries.forEach(item => {
            this.showFloatWindow = !item.isIntersecting
          })
        },
        {
          threshold: 1
        }
      )
      this.ob.observe(paymentBtn)
    })
  }
  updated() {}
  destroyed() {
    if (this.downtimeTimer) {
      clearInterval(this.downtimeTimer)
      this.downtimeTimer = null
    }
    this.PollingKey = lodash.uniqueId()
    this.ob.disconnect()
  }
}
