import lodash from 'lodash'
import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'

@Component
export class MixinsAllCalendar extends Vue {
  get mergeThis() {
    let currentMoment = moment(this.$ajax.serviceDate.valueOf())
    const [y, m, d] = currentMoment
      .format('YYYY-MM-DD')
      .split('-')
      .map(v => parseInt(v))
    const [minYear, minMonth] = currentMoment
      .month(m - 1 - 18)
      .format('YYYY-MM-DD')
      .split('-')
      .map(v => parseInt(v))
    currentMoment = moment(this.$ajax.serviceDate.valueOf())
    const [maxYear, maxMonth] = currentMoment
      .month(m - 1 + 18)
      .format('YYYY-MM-DD')
      .split('-')
      .map(v => parseInt(v))
    return {
      y,
      m,
      d,
      currentYear: y,
      currentMonth: m,
      currentDay: d,
      minYear,
      minMonth,
      maxYear,
      maxMonth
    }
  }

  y: number = this.mergeThis.y // 年
  m: number = this.mergeThis.m // 月
  d: number = this.mergeThis.d // 日
  currentYear: number = this.mergeThis.currentYear // 当前的年份
  currentMonth: number = this.mergeThis.currentMonth // 当前的月份
  currentDay: number = this.mergeThis.currentDay // 当前的天数
  maxYear: number = this.mergeThis.maxYear
  maxMonth: number = this.mergeThis.maxMonth
  minYear: number = this.mergeThis.minYear
  minMonth: number = this.mergeThis.minMonth
  activeYear: number = 0 // 点击的年份
  activeMonth: number = 0 // 点击的月份
  activeDay: number = 0 // 点击的天数
  dataSource: Array<any> = [] // 本月已发布课时的数据
  live: Array<any> = [] // 某一天的直播课
  saying: string = '' // 没有直播时，展示默认的话术
  calendar: any = {}
  loading: boolean = true

  get ClasshourPageStore() {
    return this.$store.$storeCourse.Details.ClasshourLive
  }

  get sayingArr() {
    return this.calendar.saying
  }

  get calendarImg() {
    return this.calendar.calendarImg
  }

  /** 是否展示回到本月按钮 */
  get isShowBackCurrentMonth() {
    return this.y !== this.currentYear || this.m !== this.currentMonth
  }

  /** 左侧按钮消失 */
  get leftButtonDisappear() {
    return this.y === this.minYear && this.m === this.minMonth
  }

  /** 右侧按钮消失 */
  get rightButtonDisappear() {
    return this.y === this.maxYear && this.m === this.maxMonth
  }

  /** 是否是今天 */
  isCurrentDay(item) {
    return parseInt(item.year) === this.currentYear && parseInt(item.month) === this.currentMonth && parseInt(item.day) === this.currentDay
  }

  /** 改变点击的某一天 */
  changeActive(date) {
    if (this.rightButtonDisappear && ((this.y === date.y && date.m > this.m) || date.y > this.y)) return
    else if (this.leftButtonDisappear && ((this.y === date.y && date.m < this.m) || date.y < this.y)) return
    else {
      if (this.y !== date.y || this.m !== date.m) {
        this.y = date.y
        this.m = date.m
        this.onLoad()
      } else {
        this.y = date.y
        this.m = date.m
      }
      this.activeYear = date.y
      this.activeMonth = date.m
      this.activeDay = date.d
      this.changeDayGetLive()
    }
  }

  /** 点击某一天获取当前直播内容 */
  changeDayGetLive() {
    let displayDate = this.activeDay
      ? { y: this.activeYear, m: this.activeMonth, d: this.activeDay }
      : { y: this.currentYear, m: this.currentMonth, d: this.currentDay }
    this.live = lodash.filter(this.allCalendarLiveData[`${this.y}-${this.m}`], item => {
      const [y, m, d] = moment(item.startTime)
        .format('YYYY-MM-DD')
        .split('-')
        .map(v => parseInt(v))
      return (
        displayDate.y === y &&
        displayDate.m === m &&
        displayDate.d === d &&
        (item.sort === 0 || item.sort === 9999 ? item.publishStatus === 1 : true)
      )
    })
    if (!this.live?.length) {
      let words = JSON.parse(localStorage.getItem('saying'))
      // 如果本地存在语录 & 数据是今天的，语录不会改变
      if (words && words.y === this.currentYear && words.m === this.currentMonth && words.d === this.currentDay) this.saying = words.saying
      else {
        this.saying = this.sayingArr[Math.floor(Math.random() * this.sayingArr.length)]
        let says = {}
        says['y'] = this.currentYear
        says['m'] = this.currentMonth
        says['d'] = this.currentDay
        says['saying'] = this.saying
        localStorage.setItem('saying', JSON.stringify(says))
      }
    }
  }

  isHoverLeft: boolean = false
  isHoverRight: boolean = false

  style(disapper) {
    return {
      opacity: disapper ? '0.4' : '1',
      pointerEvents: disapper ? 'none' : 'auto'
    }
  }

  async onLoad() {
    this.onInit()
    let y = this.y
    let m = this.m
    let res =
      !!lodash.keys(this.allCalendarLiveData).length &&
      lodash.keys(this.allCalendarLiveData).find(item => {
        return `${y}-${m}` === item
      })
    if (!res) {
      let date = this.currMonthData()[0]
      let dataSource = await this.$store.$storeCourse.Details.ClasshourLive.onCalendarInfo(
        moment(`${this.singlePadding(date.y)}-${this.singlePadding(date.m)}-${this.singlePadding(date.d)}`).valueOf(),
        42
      )
      this.allCalendarLiveData[`${y}-${m}`] = dataSource
      this.changeDayGetLive()
    }
    this.loading = false
    await this.asyncPrevLoad()
    await this.asyncNextLoad()
  }

  /** 上个月 */
  async toPrev() {
    if (this.m === 1) {
      this.y = this.y - 1
      this.m = 12
    } else this.m = this.m - 1
    await this.onLoad()
  }

  /** 下个月 */
  async toNext() {
    if (this.m === 12) {
      this.y = this.y + 1
      this.m = 1
    } else this.m = this.m + 1
    await this.onLoad()
  }

  /** 回到当前月 */
  goBackCurrentMonth() {
    this.y = this.currentYear
    this.m = this.currentMonth
    this.d = this.currentDay
    this.activeYear = this.currentYear
    this.activeMonth = this.currentMonth
    this.activeDay = this.currentDay
    this.changeDayGetLive()
  }

  allCalendarLiveData = {} // 存储所有日历数据，k值（年-月）  v值(长度42的数组)

  monthData(date) {
    const showtime = moment(`${this.singlePadding(date.y)}-${this.singlePadding(date.m)}-${this.singlePadding(1)}`).valueOf()
    const monthFirDay = moment(showtime).startOf('month')
    let week = monthFirDay.day() === 0 ? 7 : monthFirDay.day()
    let dayTime = 24 * 1000 * 60 * 60 // 一天多少毫秒
    // 这里是起始时间
    let time = monthFirDay.valueOf() - week * dayTime
    let monthdays = []
    for (let i = 0; i < 42; i++) {
      time += i + dayTime
      let item = moment(time)
      const [y, m, d] = item.format('YYYY-MM-DD').split('-')
      monthdays.push({ w: item.day(), y: parseInt(y), m: parseInt(m), d: parseInt(d) })
    }
    return monthdays
  }

  /** 获取上个月数据 */
  prevMonthData() {
    let date = { y: this.y, m: this.m }
    if (this.m === 1) {
      date.y = this.y - 1
      date.m = 12
    } else date.m = this.m - 1
    return this.monthData(date)
  }

  /** 获取当前月数据 */
  currMonthData() {
    let date = { y: this.y, m: this.m }
    return this.monthData(date)
  }

  /** 获取下个月数据 */
  nextMonthData() {
    let date = { y: this.y, m: this.m }
    if (this.m === 12) {
      date.y = this.y + 1
      date.m = 1
    } else date.m = this.m + 1
    return this.monthData(date)
  }

  singlePadding(num) {
    return num <= 9 ? '0' + num : num
  }

  /** 异步加载上个月直播数据 */
  async asyncPrevLoad() {
    let obj = { y: this.y, m: this.m }
    if (this.m === 1) {
      obj.y = this.y - 1
      obj.m = 12
    } else obj.m = this.m - 1
    let res =
      !!lodash.keys(this.allCalendarLiveData).length &&
      lodash.keys(this.allCalendarLiveData).find(item => {
        return `${obj.y}-${obj.m}` === item
      })
    if (!res) {
      let date = this.prevMonthData()[0]
      let dataSource = await this.$store.$storeCourse.Details.ClasshourLive.onCalendarInfo(
        moment(`${this.singlePadding(date.y)}-${this.singlePadding(date.m)}-${this.singlePadding(date.d)}`).valueOf(),
        42
      )
      this.allCalendarLiveData[`${obj.y}-${obj.m}`] = dataSource
    }
  }

  /** 异步加载下个月直播数据 */
  async asyncNextLoad() {
    let obj = { y: this.y, m: this.m }
    if (this.m === 12) {
      obj.y = this.y + 1
      obj.m = 1
    } else obj.m = this.m + 1
    let res =
      !!lodash.keys(this.allCalendarLiveData).length &&
      lodash.keys(this.allCalendarLiveData).find(item => {
        return `${obj.y}-${obj.m}` === item
      })
    if (!res) {
      let date = this.nextMonthData()[0]
      let dataSource = await this.$store.$storeCourse.Details.ClasshourLive.onCalendarInfo(
        moment(`${this.singlePadding(date.y)}-${this.singlePadding(date.m)}-${this.singlePadding(date.d)}`).valueOf(),
        42
      )
      this.allCalendarLiveData[`${obj.y}-${obj.m}`] = dataSource
    }
  }

  /** 初始化年月日 */
  onInitYearMonthDay() {
    let currentMoment = moment(this.$ajax.serviceDate.valueOf())
    const [y, m, d] = currentMoment
      .format('YYYY-MM-DD')
      .split('-')
      .map(v => parseInt(v))
    const [minYear, minMonth] = currentMoment
      .month(m - 1 - 18)
      .format('YYYY-MM-DD')
      .split('-')
      .map(v => parseInt(v))
    currentMoment = moment(this.$ajax.serviceDate.valueOf())
    const [maxYear, maxMonth] = currentMoment
      .month(m - 1 + 18)
      .format('YYYY-MM-DD')
      .split('-')
      .map(v => parseInt(v))
    const mergeThis = {
      y,
      m,
      d,
      currentYear: y,
      currentMonth: m,
      currentDay: d,
      minYear,
      minMonth,
      maxYear,
      maxMonth
    }
    Object.assign(this, mergeThis)
  }

  allCalendarData: any = {}

  onInit() {
    // 获取上个月数据
    let obj = { y: this.y, m: this.m }
    if (this.m === 1) {
      obj.y = this.y - 1
      obj.m = 12
    } else obj.m = this.m - 1
    this.allCalendarData[`${obj.y}-${obj.m}`] = this.prevMonthData()
    // 获取当前月数据
    obj = { y: this.y, m: this.m }
    this.allCalendarData[`${obj.y}-${obj.m}`] = this.currMonthData()
    // 获取下个月数据
    obj = { y: this.y, m: this.m }
    if (this.m === 12) {
      obj.y = this.y + 1
      obj.m = 1
    } else obj.m = this.m + 1
    this.allCalendarData[`${obj.y}-${obj.m}`] = this.nextMonthData()
  }
}
