





















import { Component, Prop, Vue, Provide, Inject } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import ZhiCustomBtn from './zhiCustomBtn.vue'

@Observer
@Component({
  components: {
    ZhiCustomBtn
  }
})
export default class extends Vue {
  yt_tong_sel = `${this.$store.$global.static}/images/xt_tong_selected.gif`
  yt_tong_nor = `${this.$store.$global.static}/images/xt_tong_normal.gif`
  sel: boolean = false

  onSubmit() {}
  mounted() {}
  updated() {}
  destroyed() {}
  toTop() {
    if (this.$route.name.includes('protocol')) {
      this.$router.replace({
        query: {
          action: 'toTop'
        }
      })
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
