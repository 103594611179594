




























































































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import Avatar from '@xt/www/components/headerImage/index.vue'
import { EnumNoticeType, EnumNoticeReadStatus, EnumHomeworkBizType, GiftReceiveStatus } from '@xt/client/enums'
import QrcodePopup from './popup/qrcode.vue'
import HomeworkPopup from './popup/homework.vue'
import ShineHomeworkPopup from './popup/shineHomework.vue'
import OrderDescPopup from './popup/orderDesc.vue'
import { NoticeContentType } from '@xt/client/entities/user/notice'
import { giftCache } from '@xt/client/helpers'

type ActiveItemType = {
  homeworkId?: string
  bizType?: number
  momentId?: string
  orderNo?: string
  path?: string
  verifyLogin?: boolean
}

@Observer
@Component({
  components: {
    Avatar,
    QrcodePopup,
    HomeworkPopup,
    ShineHomeworkPopup,
    OrderDescPopup
  }
})
export default class extends Vue {
  @Prop({ default: false }) showNotice: boolean

  nextPage: boolean = false
  visibleQRcode: boolean = false
  visibleHomework: boolean = false
  visibleShineHomework: boolean = false
  visibleOrderDesc: boolean = false
  activeItem: ActiveItemType = {}
  showMessageBoxShadow: boolean = false
  showSystemBoxShadow: boolean = false
  ob: IntersectionObserver | null = null

  get userStore() {
    return this.$store.$storeUser
  }

  get homeworkDetailStore() {
    return this.$store.$storeHomework.Details
  }

  get noticePageStore() {
    return this.$store.$storeUser.messageNotice
  }

  get messageNoticePagination() {
    return this.noticePageStore.noticeListPagination
  }

  get systemNoticePagination() {
    return this.noticePageStore.systemListPagination
  }

  get messageNoticeBody() {
    return {
      noticeMainType: EnumNoticeType.Account
    }
  }

  get systemNoticeBody() {
    return {
      noticeMainType: EnumNoticeType.System
    }
  }

  get EnumNoticeReadStatus() {
    return EnumNoticeReadStatus
  }

  get EnumNoticeType() {
    return EnumNoticeType
  }

  formatContent(content: string) {
    const reg = /暄桐客服|暄桐教室客服/
    const splitRes = content.split(reg)
    const matchRes = content.match(reg)
    return {
      splitRes,
      matchRes
    }
  }

  getBtnText(item: NoticeContentType) {
    switch (item.noticeSubType) {
      // 您已赠课成功
      case 10303:
      // 赠课单_被赠人收到礼物提醒
      case 10313:
      // 赠课单_自动退课前24小时提醒被赠人
      case 10323:
        return '点击领取'
      // 同学好，明天就要上课啦。正常课时
      case 10402:
      // 同学好，明天就要上课啦。头部临时课
      case 10404:
      // 同学好，明天就要上课啦。尾部临时课
      case 10406:
        return '去准备'
      // 回放剩余倒计时提醒
      case 10411:
        return '看回放'
      // 课程到期提醒
      case 10413:
      // 课程到期提醒
      case 10414:
        return ''
      // 拉手成功提醒
      case 11003:
        return '去学习'
      // 拉手失败了
      case 11004:
        return '查看课程'
      // 拉手还未成功提醒
      case 11006:
        return '分享拉手团'
      // 选帖提醒
      case 10707:
        return '去选帖'
      // 练习习作提交提醒
      case 10708:
      // 第一轮 练习习作提交结束提醒
      case 10709:
      // 第二轮 练习习作提交结束提醒
      case 10710:
      // 第三轮 练习习作提交结束提醒
      case 10711:
        return '去提交'
      // 练习习作提交通过啦
      case 10701:
      // 练习习作提交没有通过
      case 10702:
      // 正式习作提交开启提醒
      case 10712:
      // 正式习作提交通过啦
      case 10703:
      // 正式习作提交没有通过
      case 10704:
      // 正式习作提交结束提醒
      case 10713:
      // 投票结束提醒
      case 10706:
        return '去看看'
      // 投票票开始提醒
      case 10705:
        return '去投票'
      default:
        return '订单详情'
    }
  }

  @Emit('recover')
  cancelPopup(e: MouseEvent) {
    e.stopPropagation()
    this.visibleQRcode = false
    this.visibleHomework = false
    this.visibleShineHomework = false
    this.visibleOrderDesc = false
  }

  @Emit('remove')
  async messageHandler(item: NoticeContentType) {
    if (item.msgStatus !== EnumNoticeReadStatus.Read) {
      await this.noticePageStore.onReadNotice(item.id, EnumNoticeType.Account)
    }
    if (!item.bizData.valid) {
      this.$msg('内容已删除')
      return
    }
    switch (true) {
      // 作业相关的消息通知
      case `${item.noticeSubType}`.startsWith('401'):
        const homeworkBizType = await this.homeworkDetailStore.onGetHomeworkBizType(item.bizId)
        if (homeworkBizType === EnumHomeworkBizType.Show) {
          this.activeItem.path = item.appletUrl
          this.visibleQRcode = true
        } else {
          this.activeItem.homeworkId = item.bizId
          this.activeItem.bizType = EnumHomeworkBizType.Course
          this.visibleHomework = true
        }
        break
      // 日记相关的消息通知
      case `${item.noticeSubType}`.startsWith('402'):
        this.activeItem.momentId = item.bizId
        this.visibleShineHomework = true
        break
      // 作品相关的消息通知
      case `${item.noticeSubType}`.startsWith('403'):
        this.activeItem.path = item.appletUrl
        switch (item.noticeSubType) {
          // 练习习作评阅通过提醒
          case 40304:
          // 正式习作评阅通过提醒
          case 40305:
          // 练习习作评阅不通过提醒
          case 40306:
          // 正式习作评阅不通过提醒
          case 40307:
          // 评阅习作回复提醒
          case 40308:
            this.activeItem.verifyLogin = true
            break
        }
        this.visibleQRcode = true
        break
    }
  }

  async systemHandler(item: NoticeContentType) {
    if (item.msgStatus !== EnumNoticeReadStatus.Read) {
      await this.noticePageStore.onReadNotice(item.id, EnumNoticeType.System)
    }
    switch (item.noticeSubType) {
      // 您已赠课成功
      case 10303:
      // 赠课单_被赠人收到礼物提醒
      case 10313:
      // 赠课单_自动退课前24小时提醒被赠人
      case 10323:
        const status = await this.userStore.onGetGiftReceiveStatus(item.bizId)
        if (status === GiftReceiveStatus.NotReceive) {
          giftCache.delete(`gift-${item.bizId}`)
          const giftItem = this.userStore.OrderGiftsData.find(i => i.orderNo === item.bizId)
          if (giftItem) {
            this.userStore.onUpdateOrderGifts([giftItem])
          }
        } else {
          this.$msg(status === GiftReceiveStatus.HaveReceive ? '礼物已领取' : '礼物已过期')
        }
        break
      // 同学好，明天就要上课啦。正常课时
      case 10402:
      // 同学好，明天就要上课啦。头部临时课
      case 10404:
      // 同学好，明天就要上课啦。尾部临时课
      case 10406:
      // 回放剩余倒计时提醒
      case 10411:
      // 课程到期提醒
      case 10413:
      // 课程到期提醒
      case 10414:
        this.$router.push({
          name: 'course-id',
          params: { id: item.bizId }
        })
        document.body.click()
        break
      // 拉手成功提醒
      case 11003:
        this.$router.push({
          name: 'my'
        })
        document.body.click()
        break
      // 拉手失败了
      case 11004:
        this.$router.push({
          path: `/commodity/${item.bizId}`
        })
        document.body.click()
        break
      // 拉手还未成功提醒
      case 11006:
        this.$router.push({
          name: 'groupbuy-groupNo',
          params: {
            groupNo: item.bizId
          }
        })
        document.body.click()
        break
      // 选帖提醒
      case 10707:
      // 练习习作提交提醒
      case 10708:
      // 第一轮 练习习作提交结束提醒
      case 10709:
      // 第二轮 练习习作提交结束提醒
      case 10710:
      // 第三轮 练习习作提交结束提醒
      case 10711:
      // 练习习作提交通过啦
      case 10701:
      // 练习习作提交没有通过
      case 10702:
      // 正式习作提交开启提醒
      case 10712:
      // 正式习作提交通过啦
      case 10703:
      // 正式习作提交没有通过
      case 10704:
      // 正式习作提交结束提醒
      case 10713:
        this.activeItem.path = item.appletUrl
        this.activeItem.verifyLogin = true
        this.visibleQRcode = true
        break
      // 投票结束提醒
      case 10706:
      // 投票票开始提醒
      case 10705:
        this.activeItem.path = item.appletUrl
        this.visibleQRcode = true
        break
      default:
        this.activeItem.orderNo = item.bizId
        this.visibleOrderDesc = true
        this.$emit('remove')
        break
    }
  }

  stopHandler(e: MouseEvent) {
    e.stopPropagation()
  }

  async clearAllNotice(type?: 1 | 2 | 3 | 4) {
    const removeCount = await this.noticePageStore.onAllReadNotice(type)
    this.$msg(removeCount > 0 ? '操作成功' : '暂无未读消息')
  }

  openZhichiCustom(e: MouseEvent) {
    e.stopPropagation()
    const customNode = document.querySelector('#openZhichiCustom') as HTMLDivElement
    customNode.click()
  }

  @Watch('showNotice')
  updateShowNoticeHandler(value: boolean) {
    if (!value) {
      if (this.ob) {
        this.ob.disconnect()
      }
      return
    }
    this.$nextTick(() => {
      const noticeContainer = this.$refs['notice-container'] as HTMLDivElement
      const systemEntry = this.$refs['system-entry'] as HTMLDivElement
      const placeholder = this.$refs['placeholder'] as HTMLDivElement
      this.ob = new IntersectionObserver(
        entries => {
          entries.forEach(item => {
            if (item.target === systemEntry) {
              this.showMessageBoxShadow = !item.isIntersecting
            } else if (item.target === placeholder) {
              this.showSystemBoxShadow = !item.isIntersecting
            }
          })
        },
        {
          threshold: 1,
          root: noticeContainer
        }
      )
      this.ob.observe(systemEntry)
      this.ob.observe(placeholder)
    })
  }

  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
