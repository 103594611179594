/*
 * @Author: mengxd
 * @CreateTime: 2021-06-07 16:21:14
 * @Description: 去上课
 */
import { AjaxBasics, EnumApiMy } from '@xt/client'
import { EnumContentType, EnumContentTypeText, EnumProductType, EnumProductTypeQuery, EnumProductUnit } from '@xt/client/enums'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component
export class MixinsGoCourseItem extends Vue {
  @Prop({ default: {} }) dataSource
  color: boolean = true
  //默认点击去学习都显示不在提醒
  hasShowCallAlert = false
  //显示不在提醒,true为选中，false为未选中
  callMe = false

  //请求接口，更改状态【pc和h5一样】
  async changeGroupStatus() {
    let productId = this.dataSource.id
    let res = await this.$ajax.put(EnumApiMy.changeGroupStatus, { productId })
    if (res == true) {
      this.hasShowCallAlert = true
    }
    return res
  }
  //切换不在提醒
  changeCallMe() {
    this.callMe = !this.callMe
  }

  get isColor() {
    return this.color
  }

  get isAddLearnChild() {
    return !this.dataSource.showAssistantUrlFlag || this.dataSource.groupChatStatus == 2
  }
  /**
   * 课程的进行状态
   * **/
  get coursePrograss() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    let courseText = {
      mainText: '',
      subText: ''
    }
    //当前时间小于学习开始时间,未开课
    if (currentTime < this.dataSource.learnStartTime) {
      this.color = false
      courseText = {
        mainText: '即将开课',
        subText: `共${this.count + this.unit}`
      }
    }
    //进行中
    else if (currentTime > this.dataSource.learnStartTime && currentTime < this.dataSource.learnEndTime) {
      this.color = true
      courseText = {
        mainText: `进行至第${this.classhourUpdateIndex + this.unit}`,
        subText: `共${this.count + this.unit}`
      }
    }
    //已结课
    else {
      this.color = false
      courseText = {
        mainText: `已结课`,
        subText: `共${this.count + this.unit}`
      }
    }
    return courseText
  }
  get rankNo() {
    return this.dataSource.rankNo ? this.dataSource.rankNo : 0
  }
  rankText(rankNo) {
    return rankNo ? '总排名' + rankNo : '暂无排名'
  }
  //展览辅导课
  get exhibitionState() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    if (currentTime > this.dataSource.voteEndTime) return '投票结束'
    if (currentTime > this.dataSource.voteStartTime) return '正在投票'
    if (currentTime > this.dataSource.formalEndTime) return '备展结束'
    if (this.dataSource.mainType === 1 || this.dataSource.mainType === 2)
      return this.dataSource.taskSchedule !== 0 ? `进行至第${this.dataSource.taskSchedule}关` : '选择临摹习作'
    if (this.dataSource.mainType === 3) return '练习习作提交'
    return '正式习作提交'
  }

  get exhibitionTips() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    if (currentTime > this.dataSource.voteEndTime || currentTime > this.dataSource.voteStartTime) return this.rankText(this.rankNo)
    if (currentTime > this.dataSource.formalEndTime)
      return `距离投票开启剩余${this.computeDay(currentTime, this.dataSource.voteStartTime)}天`
    if (currentTime > this.dataSource.formalStartTime)
      return `距离正式习作提交结束剩余${this.computeDay(currentTime, this.dataSource.formalEndTime)}天`
    if (currentTime > this.dataSource.previewStartTime)
      return `距离正式习作提交开启剩余${this.computeDay(currentTime, this.dataSource.formalStartTime)}天`
    return `距离练习习作提交开启剩余${this.computeDay(currentTime, this.dataSource.previewStartTime)}天`
  }
  computeDay(currentTime, endtime) {
    return Math.floor(this.moment(endtime).diff(currentTime, 'hour') / 24) || 1
  }
  /** 团购的状态 0 未确认未添加 1 已确认未添加 2 已添加 **/
  get isAddGroup() {
    return this.dataSource.groupChatStatus
  }
  /** 当前的关卡进度 **/
  get classhourUpdateIndex() {
    return this.dataSource.taskSchedule
  }
  /** 当前的课程数量 **/
  get count() {
    return this.dataSource.bizProdCount ? this.dataSource.bizProdCount : 0
  }
  get id() {
    return this.dataSource.id
  }
  get bizId() {
    return this.dataSource.bizId
  }
  get coverUrl() {
    return this.dataSource.coverUrl
  }
  get btnText() {
    if (this.type == EnumProductTypeQuery.Exhibition) {
      let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
      if (currentTime < this.dataSource.voteStartTime) {
        return '去备展'
      }
      return '去观展'
    }
    return '去学习'
  }
  /** 当前的课程类型 **/
  get type() {
    if (this.dataSource.productType == EnumProductType.fullstage || this.dataSource.productType == EnumProductType.single) {
      return EnumProductTypeQuery.Course
    } else if (this.dataSource.productType == EnumProductType.dayCourse) {
      return EnumProductTypeQuery.DayCourse
    } else {
      return EnumProductTypeQuery.Exhibition
    }
  }
  /** 当前的课程单位 **/
  get unit() {
    switch (this.type) {
      case EnumProductTypeQuery.Course:
        return EnumProductUnit.LiveCourseCountUnit
      case EnumProductTypeQuery.DayCourse:
        return EnumProductUnit.DayCourseCountUnit
      case EnumProductTypeQuery.Exhibition:
        return EnumProductUnit.ExhibitonCourseCountUnit
    }
  }
  /** 当前的课程内容类型 **/
  get contentType() {
    // 1 书法课，2读书课，3写作课，4养生课，5国画课，6实物商品，7书画课
    switch (this.dataSource.contentType) {
      case EnumContentType.Painting:
        return EnumContentTypeText.Painting
      case EnumContentType.Calligraphy:
        return EnumContentTypeText.Calligraphy
      case EnumContentType.CalligraphyAndReading:
        return EnumContentTypeText.CalligraphyAndReading
      case EnumContentType.Health:
        return EnumContentTypeText.Health
      case EnumContentType.Physical:
        return EnumContentTypeText.Physical
      case EnumContentType.Reading:
        return EnumContentTypeText.Reading
      case EnumContentType.Writing:
        return EnumContentTypeText.Writing
      default:
        return EnumContentTypeText.Calligraphy
    }
  }

  mounted() {}
  updated() {}
  destroyed() {
    //this.Pagination.onReset()
  }
}
