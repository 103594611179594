





























import { Component, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import moment from 'moment'
import { AjaxBasics } from '@xt/client'

@Observer
@Component
export default class PageView extends Vue {
  onOkAgreeLock = false
  cancelVisible = false
  currentTime: number = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
  interval: NodeJS.Timer

  downtimeText: string = this.downtimeTextInit(this.currentTime, this.PageStore.UserInfo.cancelTime)

  get PageStore() {
    return this.$store.$storeUser
  }

  downtimeTextInit(currentTime, endtime) {
    let diff = endtime - currentTime
    if (diff > 24 * 60 * 60 * 1000) {
      return this.computeDay(currentTime, endtime)
    } else {
      return moment(diff + 57600000).format('HH:mm:ss')
    }
  }

  computeDay(currentTime, endtime) {
    return `${Math.floor(this.moment(endtime).diff(currentTime, 'hour') / 24)}天`
  }

  async exitLogout() {
    this.interval = null
    clearInterval(this.interval)
    await this.PageStore.onLogout()
    await this.PageStore.onOutLogin()
    window.location.replace(this.$router.resolve('/').href)
  }

  async onCancelAgree() {
    this.cancelVisible = false
  }

  async onOkAgree() {
    if (this.onOkAgreeLock) return

    try {
      this.onOkAgreeLock = true
      await this.PageStore.cancelAccount(false)
      await this.PageStore.onGetUserInfo()
      this.interval = null
      clearInterval(this.interval)
      window.location.replace(this.$router.resolve('/').href)
      this.cancelVisible = false
    } catch (error) {
      console.log(error)
    } finally {
      this.onOkAgreeLock = false
    }
  }

  async abandonLogout() {
    this.cancelVisible = true
  }

  created() {
    this.interval = setInterval(() => {
      let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
      // Thu Jan 01 1970 08:00:00 GMT+0800 (中国标准时间) {} 所以 - 28800000
      let diff = this.PageStore.UserInfo.cancelTime - currentTime
      if (diff <= 0) {
        this.interval = null
        clearInterval(this.interval)
        this.exitLogout()
        return
      } else if (diff > 24 * 60 * 60 * 1000) {
        this.downtimeText = this.computeDay(currentTime, this.PageStore.UserInfo.cancelTime)
      } else {
        this.downtimeText = moment(diff - 28800000).format('HH:mm:ss')
      }
    }, 200)
  }
  mounted() {
    ;(document.querySelector('.xt-layout-content') as any).style.backgroundColor = '#F6F6F6'
  }
  updated() {}
  beforeDestroy() {
    ;(document.querySelector('.xt-layout-content') as any).style.backgroundColor = '#ffffff'
  }
}
