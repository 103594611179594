

























































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import Item from './views/item.vue'
import { MixinsGoCourse } from '@xt/client/mixins'
import ViewStudyCalendar from './views/studyCalendar.vue'
import CourseTab from './views/tabs.vue'
import { EnumProductTypeQuery } from '@xt/client/enums'

@Observer
@Component({
  components: { Item, ViewStudyCalendar, CourseTab },
  async asyncData(ctx) {
    // 读取远程JSON文件
    const res = await ctx.store.$ajax.get(
      `/mocks/studyCalendar.json`,
      {},
      { 'Cache-Control': 'no-store', 'If-Modified-Since': '0' },
      { target: ctx.store.$global.production ? 'https://static.xuantong.cn' : 'https://testing-static.xuantong.cn' }
    )

    // 6.30
    // await ctx.store.$storeUser.onGetClaimedGift()

    //单元测试用本地JSON文件
    //const res = JSONDATA
    return {
      calendar: res
    }
  }
})
export default class PageView extends Mixins(MixinsGoCourse) {
  @Prop({}) offsetTop: number
  haveNoneCourse = false
  loading = true
  calendar: any = {}

  toClass() {
    this.$router.push({ name: 'course' })
  }

  get userStore() {
    return this.$store.$storeUser
  }

  get EnumProductTypeQuery() {
    return EnumProductTypeQuery
  }

  async onloading() {
    try {
      this.loading = true
      this.myProductList = await this.$store.$my.onGetMyProductList()
      this.courseList = this.myProductList.progressingResultVOS
      if (this.liveCourse.length + this.dayCourse.length + this.exhibitionCourse.length + this.selfStudyCourse.length <= 0) {
        this.haveNoneCourse = true
      }
      this.loading = false
    } catch (error) {
      this.loading = false
    }
  }

  created() {
    this.onloading()
  }
  updated() {}
  destroyed() {}
}
