






























import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import LazyImg from '@/components/lazyImg/index.vue'
import { AjaxBasics } from '@xt/client'
import moment from 'moment'

@Observer
@Component({
  name: 'SelfStudyCourseComp',
  components: { LazyImg }
})
export default class SelfStudyCourseComp extends Vue {
  @Prop({}) dataSource: any
  @Prop({}) className: string

  statusText: any = ''
  visibleSignupBtn: boolean = false // 是否显示立即报名按钮
  downTimer: NodeJS.Timer
  countdownText: any = {}

  get forward() {
    return this.dataSource.isLookforward
  }

  toDetail() {
    if (this.forward) return this.$msg('敬请期待~')
    this.$router.push({
      path: `/commodity/${this.dataSource.productId}`
    })
  }

  /**
   * 倒计时及状态切换的实现
   */
  downtime(signupStart: number, signupEnd: number) {
    this.downTimer = setInterval(() => {
      let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
      let diff: number = signupEnd - currentTime
      switch (true) {
        case diff <= 0: // 如果结束了那么切换结束状态停止计时器
          clearInterval(this.downTimer)
          this.visibleSignupBtn = false
          return (this.statusText = { text: '报名结束' })
        case diff > 24 * 60 * 60 * 1000: // 如果倒计时大于24小时显示天
          this.visibleSignupBtn = true
          // return this.statusText = `报名剩余：${this.computeDay(currentTime, signupEnd)}天`
          return (this.statusText = { text: '正在报名', hot: true })
        default:
          this.visibleSignupBtn = true
          let hms = moment(signupEnd - currentTime - 28800000).format('HH:mm:ss')
          // return this.statusText = `报名剩余：<div style='width: ${50 / 16}rem'>${hms}</div>`
          return (this.statusText = { text: '正在报名', hot: true })
      }
    }, 200)
  }

  get btn() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    if (!this.dataSource.courseSignUpStartTime) {
      return { text: '即将报名', color: '-red' }
    }
    if (currentTime < this.dataSource.courseSignUpStartTime) {
      return {
        text: `报名时间：${moment(this.dataSource.courseSignUpStartTime).format('YYYY.MM.DD')}`,
        color: '-red'
      }
    }
    // 当前时间大于转报结束时间且课程结束则 已结课
    if (currentTime >= this.dataSource.courseEndTime) {
      return { text: '已结课', color: '-black' }
    }
    // 当前时间大于转报结束时间且大于报名结束时间且课程已开启则 开课中
    if (currentTime >= this.dataSource.courseSignUpEndTime && currentTime >= this.dataSource.courseStartTime) {
      return { text: '开课中', color: '-yellow' }
    }
    // 当前时间大于转报结束时间且课程未开启则 转报结束
    if (currentTime >= this.dataSource.courseSignUpEndTime && currentTime <= this.dataSource.courseStartTime) {
      return { text: '报名结束', color: '-red' }
    }
    // 当前时间大于报名开始时间且小于报名结束时间则  我要报名
    if (currentTime >= this.dataSource.courseSignUpStartTime && currentTime < this.dataSource.courseSignUpEndTime) {
      return { text: this.countdownText, color: '-red', hot: true }
    }
    // 默认
    return { text: '已结课', color: '-black' }
  }

  get moment() {
    return moment
  }

  /**
   * 计算差几天
   */
  computeDay(currentTime: number, endtime: number): number {
    return Math.floor(this.moment(endtime).diff(currentTime, 'hour') / 24)
  }

  destroyed() {
    clearInterval(this.downTimer)
  }

  get money() {
    let yuan = (this.dataSource.coursePrice || 0) / 100
    return yuan % 1 != 0 ? yuan.toFixed(2) : yuan
  }
  countdown() {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    // 若报名未结束且报名开始则进行报名倒计时
    if (currentTime > this.dataSource.courseSignUpStartTime && currentTime < this.dataSource.courseSignUpEndTime) {
      return 1
    }
    // 不需要倒计时
    return 0
  }
  interval = null
  countdownInterval(endtime) {
    this.computed(endtime)
    // this.interval = setInterval(() => {
    //   this.computed(endtime)
    // }, 1000);
    //去掉过渡态
    this.interval = setInterval(() => {
      this.computed(endtime)
    }, 1000)
  }
  computed(endtime) {
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    let diff = endtime - currentTime
    if (diff <= 0) {
      clearInterval(this.interval)
      return (this.countdownText = '')
    }

    if (diff > 24 * 60 * 60 * 1000) {
      // this.countdownText = (this.countdown() == 2 ? "转报" : "报名") + '剩余 ' + this.computeDay(currentTime, endtime) + "天"
      this.countdownText = '正在报名'
    } else {
      // return this.countdownText = (this.countdown() == 2 ? "转报" : "报名") + '剩余 ' + `<div style="display: inline-block;width:${60 / 16}rem">`+moment(diff + 57600000).format("HH:mm:ss") + '</div>'
      return (this.countdownText = '正在报名')
    }
  }
  mounted() {
    if (this.countdown() == 1) this.countdownInterval(this.dataSource.courseSignUpEndTime)
  }
}
