
















































































import { Observer } from 'mobx-vue'
import { Vue, Component } from 'vue-property-decorator'
import OrderItemScript from './script'
// import OrderProduct from "../product/product.vue"
import OrderBtns from '../btns/btns.vue'
import GroupBuy from '../groupbuy/groupbuy.vue'
import LazyImg from '@/components/lazyImg/index.vue'
import OrderDescPopup from '../../../../../../components/orderDesc/index.vue'
import Teleport from '@xt/client/mixins/components/teleport.vue'

@Observer
@Component({
  name: 'OrderItemComp',
  components: {
    // OrderProduct,
    OrderBtns,
    GroupBuy,
    LazyImg,
    OrderDescPopup,
    Teleport
  }
})
export default class OrderItemComp extends OrderItemScript {}
