import { render, staticRenderFns } from "./tab_introduction.vue?vue&type=template&id=305f39bb&scoped=true&"
import script from "./tab_introduction.vue?vue&type=script&lang=ts&"
export * from "./tab_introduction.vue?vue&type=script&lang=ts&"
import style0 from "./tab_introduction.vue?vue&type=style&index=0&id=305f39bb&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "305f39bb",
  null
  
)

export default component.exports