import { AjaxBasics } from '../../helpers'

import {
  EnumProductTypeNameSimple,
  EnumProductStatusByShow,
  EnumProductUnit,
  EnumProductStatus,
  EnumProductType,
  EnumContentType,
  EnumContentTypeText,
  EnumProductStyle
} from '../../enums/product'
import moment from 'moment'

// 原始数据类型
export type ProductApiOriginDataType = {
  activityType: number // 活动类型（0:无活动，1:拼团）二进制存储
  bizId: number // 业务表id
  bizProdCount: number // 子商品数：课时/关卡数量/商品件数
  contentType: number // 内容类型（1 书法课，2读书课，3写作课，4养生课，5画画课，6实物商品，7书画课）
  coverUrl: string // 封面图
  coverUrlH5?: string //方图
  discountPrice: number // 优惠价
  groupPrice: number // 团购价
  id: number // 商品id
  learnEndTime: number // 学习结束时间
  learnStartTime: number // 学习开始时间
  originalPrice: number // 原价
  productName: string // 商品标题
  productSubName: string // 商品副标题
  productType: number // 商品类型
  signUpEndTime: number // 报名截止时间
  signUpStartTime: number // 报名开始时间
  canSignUp: boolean //是否可报名
  voteStartTime?: number //展览投票开始时间
  voteEndTime?: number //展览投票结束时间
  rankNo?: number // 展览当前的总排名
  formalEndTime?: number // 展览正式习作结束时间
  formalStartTime?: number // 展览正式习作结束时间
  taskSchedule?: number //展览当前关卡进行到多少了
  previewEndTime?: number //展览预提交时间
  groupChatStatus?: boolean //是否加群
  type?: string //类型 live exhibition day
  payAmount?: number
  activityPageUrl?: string //活动页的地址
}

// 实体类
export default class Product {
  constructor(public origin: ProductApiOriginDataType) {}

  get currentTime(): number {
    return AjaxBasics.serviceDate.utcOffset(+8).valueOf()
  }

  /**
   * 活动的二进制字符串
   */
  get activityType(): string {
    return this.origin?.activityType?.toString(2)
  }

  /**
   * 活动的二进制字符串
   *
   * 最后一位是 "1" 则开启了团购活动
   */
  get isOpenGroup(): boolean {
    return this.activityType ? !!parseInt(this.activityType[this.activityType.length - 1]) : false
  }

  /**
   * 直播课
   */
  get isLiveCourse() {
    return this.origin.productType === EnumProductType.single || this.origin.productType === EnumProductType.fullstage
  }

  /**
   * 展览进阶课
   */
  get isShowCourse() {
    return this.origin.productType === EnumProductType.onlineExhibition || this.origin.productType === EnumProductType.offlineExhibition
  }

  /**
   * 教练日课
   */
  get isDayCourse() {
    return this.origin.productType === EnumProductType.dayCourse
  }

  /**
   * 等待报名
   *
   * 当前时间小于报名开始时间||当前时间小于报名结束时间&报名未开启
   */
  get isWaitEnroll() {
    return this.currentTime < this.origin.signUpStartTime || !this.origin.canSignUp
  }

  get isShowEnrollTime() {
    return this.origin.canSignUp && this.currentTime < this.origin.signUpStartTime
  }

  /**
   * 报名中
   *
   * 当前时间大于报名开始时间&当前时间小于报名结束时间
   */
  get isEnrolling() {
    return this.currentTime > this.origin.signUpStartTime && this.currentTime < this.origin.signUpEndTime && this.origin.canSignUp
  }

  /**
   * 报名结束
   *
   * 当前时间大于报名结束时间&当前时间小于课程开启时间
   */
  get isEnrollEnd() {
    return this.currentTime > this.origin.signUpEndTime && this.currentTime < this.origin.learnStartTime
  }

  /**
   * 开课中
   *
   * 当前时间大于课程开始时间&当前时间大于课程结束时间
   */
  get isStartLearn() {
    return this.origin.learnStartTime < this.currentTime && this.origin.learnEndTime > this.currentTime
  }

  /**
   * 已结课
   *
   * 当前时间大于课程结束时间
   */
  get isEndLearn() {
    return this.origin.learnEndTime < this.currentTime
  }

  /**
   * 商品的当前状态
   */
  get productStatus() {
    switch (true) {
      case !this.origin.canSignUp:
        return EnumProductStatus.WaitEnroll
      case this.isShowEnrollTime:
        return `报名时间：${moment(this.origin.signUpStartTime).format('YYYY.MM.DD')}`
      case this.isEnrolling:
        return EnumProductStatus.Enrolling
      case this.isEnrollEnd:
        return EnumProductStatus.EnorllEnd
      case this.isStartLearn:
        return this.isShowCourse ? EnumProductStatusByShow.StartLearn : EnumProductStatus.StartLearn
      case this.isEndLearn:
        return this.isShowCourse ? EnumProductStatusByShow.EndLearn : EnumProductStatus.EndLearn
      default: // 其他未知情况（异常）
        return this.isShowCourse ? EnumProductStatusByShow.EndLearn : EnumProductStatus.EndLearn
    }
  }

  /**
   * 样式后缀
   */
  get productStyle() {
    switch (true) {
      case !this.origin.canSignUp:
        return EnumProductStyle.WaitEnroll
      case this.isShowEnrollTime:
        return `${EnumProductStyle.WaitEnroll} font-size-9`
      case this.isEnrolling:
        return EnumProductStyle.Enrolling
      case this.isEnrollEnd:
        return EnumProductStyle.EnorllEnd
      case this.isStartLearn:
        return EnumProductStyle.StartLearn
      case this.isEndLearn:
        return EnumProductStyle.EndLearn
      default: // 其他未知情况（异常）
        return EnumProductStyle.EndLearn
    }
  }

  /**
   * 当前商品的类型
   */
  get contentType() {
    switch (this.origin.contentType) {
      case EnumContentType.Painting:
        return EnumContentTypeText.Painting
      case EnumContentType.Calligraphy:
        return EnumContentTypeText.Calligraphy
      case EnumContentType.CalligraphyAndReading:
        return EnumContentTypeText.CalligraphyAndReading
      case EnumContentType.Health:
        return EnumContentTypeText.Health
      case EnumContentType.Physical:
        return EnumContentTypeText.Physical
      case EnumContentType.Reading:
        return EnumContentTypeText.Reading
      case EnumContentType.Writing:
        return EnumContentTypeText.Writing
      default:
        return EnumContentTypeText.Calligraphy
    }
  }

  /**
   * 获取商品数量单位
   */
  get countUnit() {
    switch (true) {
      case this.isDayCourse:
        return EnumProductUnit.DayCourseCountUnit
      case this.isShowCourse:
        return EnumProductUnit.ExhibitonCourseCountUnit
      case this.isLiveCourse:
        return EnumProductUnit.LiveCourseCountUnit
      default:
        return EnumProductUnit.PhysicalCountUnit
    }
  }

  get productTypeNameSimple() {
    switch (true) {
      case this.isDayCourse:
        return EnumProductTypeNameSimple.DayCourse
      case this.isShowCourse:
        return EnumProductTypeNameSimple.Exhibition
      case this.isLiveCourse:
        return EnumProductTypeNameSimple.Live
      default:
        return EnumProductTypeNameSimple.Live
    }
  }
}
