import { BindAll } from 'lodash-decorators'
import { Encryption } from '../../helpers/encryption'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import { EnumApiCurrency } from '../../api'
import md5 from 'crypto-js/md5'
import { action, observable } from 'mobx'
interface OSSoptions {
  accessKeyId?: string
  // 访问身份验证中用到用户标识

  callbackBody?: string
  // OSS回调参数

  dir?: string
  // 上传文件夹路径前缀

  expire?: number
  // 签名有效期

  host?: string
  // oss对外服务的访问域名

  policy?: string
  // 用户表单上传的策略, 经过base64编码过的字符串

  signature?: string
  // 对policy签名后的字符串
}
@BindAll()
export class ControllerOSS {
  constructor(protected $ajax: AjaxBasics) {}
  loading = false
  @observable
  options: OSSoptions = {}
  @action
  onSetOptions(options) {
    this.options = options
  }
  /**
   *
   * @param picType 图片类型，0，生成图片4件套，1 只生成图片原图
   * @param type 上传类型 1图片，2视频，3文件
   */
  async onPolicy(picType: 0 | 1 = 1, type: 1 | 2 | 3 = 1) {
    if (this.loading || this.$ajax.serviceDate.diff(this.options.expire, 'minutes') < -1) {
      return
    }
    this.loading = true
    try {
      const res = await this.$ajax.get(EnumApiCurrency.files, { picType, type })
      this.onSetOptions(res)
    } catch (error) {}
    this.loading = false
  }
  /**
   * 上传前 文件 MD5
   * @param file 文件
   * @param random 随机
   */
  beforeFileMD5(file, random = true) {
    return new Promise((res, rej) => {
      if (random) {
        file.md5 = Encryption.uniqueId()
        return res(file)
      }
      const reader = new FileReader()
      reader.readAsDataURL(file)
      console.time('MD5')
      reader.onload = event => {
        file.md5 = md5(event.target.result.toString()).toString()
        console.timeEnd('MD5')
        res(file)
      }
    })
  }
  /**
   * 上传前 参数
   * @param file
   */
  beforeData(file) {
    return {
      key: `${this.options.dir}${file.md5}${/\.[^\.]+$/.exec(file.name)}`,
      OSSAccessKeyId: this.options.accessKeyId,
      policy: this.options.policy,
      signature: this.options.signature,
      success_action_status: 200,
      callback: this.options.callbackBody
    }
  }
  /**
   * 转换blob类型为file
   * @param blob blob文件
   * @param options 文件参数
   */
  blobToFile(blob, options?: { name?; type? }) {
    const files = new window.File([blob], options.name || 'image', {
      type: options.type || 'image/jpeg'
    })
    return files
  }
  /**
   * 获取私有访问的url
   */
  onGetFilesUrl(filePath): Promise<any> {
    return this.$ajax.request({ url: EnumApiCurrency.FilesUrl, body: { filePath }, responseType: 'text' }).toPromise()
  }
  /**
   * 微信上传
   * @param serverId
   */
  onWxUploadFiles(serverId) {
    return this.$ajax.post(EnumApiCurrency.wxFiles, { mediaId: serverId })
  }
}
