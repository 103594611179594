/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2020-10-20 20:25:53
 * @modify date 2020-10-20 20:25:53
 * @desc 课程地图页
 */
import { Component, Prop, Vue, Provide, Mixins } from 'vue-property-decorator'
import lodash from 'lodash'
import { MixinsCourseHelpers } from './helpers'
import { EnumCourseSignupType } from '../../enums'
import { EnumApiOrder } from '../../api'
import { Course } from '../../entities'
import moment from 'moment'

@Component
export class MixinsCourseSignup extends Mixins(MixinsCourseHelpers) {
  /** 课程数据 */
  @Prop({ default: () => ({}) }) dataSource: Course
  /** 按钮类型 默认自己购买 */
  @Prop({ default: EnumCourseSignupType.Purchase }) type: EnumCourseSignupType
  /** 进详情 */
  @Prop({ default: false }) to
  @Prop() buttonText
  @Prop() buttonType
  // 平台
  get platform() {
    return this.$store.$global.platform
  }
  get PageStore() {
    return this.$store.$storeCourse
  }
  get UserStore() {
    return this.$store.$storeUser
  }
  /**
   * 自己买
   * @readonly
   * @memberof MixinsCourseSignup
   */
  get IsPurchase() {
    return lodash.eq(this.type, EnumCourseSignupType.Purchase)
  }
  /**
   * 送人
   * @readonly
   * @memberof MixinsCourseSignup
   */
  get IsGift() {
    return lodash.eq(EnumCourseSignupType.Gift, this.type)
  }

  isSignupEnd() {
    if (this.type == 'Give' || this.IsGift) {
      return false
    }
    const diff = moment(this.dataSource.signUpEndTime).diff(this.$ajax.serviceDate, 'seconds')
    return diff <= 0
  }

  /** 禁用 购买按钮 */
  get disabled() {
    let serviceDate = this.$ajax.serviceDate.utcOffset(+8).valueOf()
    if (this.IsPurchase) {
      if (this.dataSource.isHasOwn) {
        return true
      }
      if (this.dataSource.isSingleHasOwn) {
        return (
          !this.dataSource.isSignupTurn ||
          this.dataSource.singles.filter(item => {
            return !item.isHasOwn && item.original.signUpEndTime < serviceDate
          }).length > 0
        )
      }
      return !this.dataSource.isSignup
    }
    return !this.dataSource.isSignupGift
  }
  get btnText() {
    // 自买文字
    if (this.IsPurchase) {
      return this.dataSource.signupText
    }
    if (this.buttonText) {
      return this.buttonText
    }
    return {
      [EnumCourseSignupType.Purchase]: '立即报名',
      [EnumCourseSignupType.Give]: '赠课给他人',
      [EnumCourseSignupType.Gift]: '赠课'
    }[this.type]
  }
  get btnType() {
    if (this.buttonType) {
      return this.buttonType
    }
    return {
      [EnumCourseSignupType.Purchase]: 'yellow',
      [EnumCourseSignupType.Give]: { PC: 'primary' }[this.platform],
      [EnumCourseSignupType.Gift]: undefined
    }[this.type]
  }
  get block() {
    return {
      [EnumCourseSignupType.Purchase]: true,
      [EnumCourseSignupType.Give]: true,
      [EnumCourseSignupType.Gift]: undefined
    }[this.type]
  }
  get classStr() {
    return 'xt-signup-' + this.type
  }

  /**
   * 显示礼物图标
   * @readonly
   * @memberof MixinsCourseSignup
   */
  get showGiftIcon() {
    if (lodash.eq(this.type, EnumCourseSignupType.Gift)) {
      if (this.$route.fullPath.includes('/course/')) {
        return this.dataSource.isHasOwn && this.dataSource.isSignupGift
      }
      return this.dataSource.originalPrice
    }
    return true
  }

  /**
   * 协议 显示
   * @memberof MixinsCourseSignup
   */
  VisibleAgreement = false

  /**
   * 是否有待支付订单
   * **/
  VisibleHasPay = false

  /**
   * 待支付订单详细信息
   * **/
  hasPayInfo = {
    orderNo: '',
    payAmount: '',
    score: 0,
    status: '',
    virtualCurrency: 0,
    oldOrder: '',
    groupNo: '',
    oldOrderNo: '',
    productId: '',
    activityType: 0
  }
  /**
   * 重新下单
   * **/
  async placeOrder() {
    //先取消原订单
    let orderNo = this.hasPayInfo.orderNo
    let query = { orderNo }
    let res = await this.$ajax.put(EnumApiOrder.OrderCancel, query)

    //如果取消成功,则弹出来协议
    if (res == true) {
      this.VisibleHasPay = false //隐藏掉重新下单
      this.VisibleAgreement = true
      this.$router.replace({
        query: { ...this.$route.query, memberBeforeOption: 'false' }
      })
    }
  }
  /**
   * 继续支付
   * **/
  rePayOrder() {
    const query = {
      productId: lodash.get(this.dataSource, 'original.productInfo.id'),
      orderNo: this.hasPayInfo.orderNo,
      give: this.IsPurchase == true ? String(false) : String(true),
      group: this.hasPayInfo.activityType == 0 ? 'false' : 'true',
      groupNo: this.hasPayInfo.groupNo,
      memberBeforeOption: 'true'
    }
    // 微信内 无 wxOpenid id
    if (this.$store.$global.WechatBowser && this.$store.$global.platform === 'Mobile' && !this.$store.$storeUser.wxOpenid) {
      // 获取 code 换取 openid
      window.location.replace(
        this.$store.$wechat.getAuthorizeUrl(window.location.origin + this.$router.resolve({ name: 'payment', query }).href, 'snsapi_base')
      )
      return false
    }
    this.$router.push({ name: 'payment', query })
  }

  /**
   * 报名购买
   */
  async onSignup() {
    try {
      if (this.to) {
        return this.$router.push({
          name: 'course-id',
          params: { id: lodash.get(this.dataSource, 'id', this.to) }
        })
      }
      // 检测用户是否登录
      this.$InspectUser()
      this.onCheckDevice()

      //此处是下单校验
      let productId = lodash.get(this.dataSource, 'original.productInfo.id')
      if (!productId) {
        throw Error('没有productId')
      }
      if (this.IsGift || this.type == 'Give') {
        this.VisibleAgreement = true
        return false
      }
      let group = this.$route.query?.group == 'true' ? 'true' : 'false'
      let query = { productId, group, groupNo: this.$route.query?.groupNo }

      let toBePaid: any = await this.$ajax.get(EnumApiOrder.checkProduct, query)

      if (toBePaid.oldOrder && toBePaid.oldOrderNo != '') {
        this.VisibleHasPay = true
        this.hasPayInfo = toBePaid
        this.hasPayInfo.orderNo = toBePaid.oldOrderNo
        return false
      }

      this.VisibleAgreement = true
      // if (this.VisibleAgreement && this.platform === 'H5') {
      //     // 更改路径。返回时 会自定关闭 预览
      //     this.$router.push({
      //         query: lodash.merge({}, this.$route.query, { popup: Date.now() }),
      //     });
      // }
    } catch (error) {
      console.log('LENG ~ MixinsCourseSignup ~ onSignup ~ error', error)
      this.VisibleAgreement = false
    }
  }
  /**
   * 检测用户是否手机邮箱
   */
  onCheckDevice() {
    if (this.UserStore.needBindDevice) {
      if (this.platform === 'Mobile') {
        this.$router.push({
          name: 'other-bind'
        })
        throw this.$EnumLocaleLinks.links_bind_device
      }
      this.UserStore.onToggleModal(this.$EnumLocaleLinks.links_bind_device)
      throw this.$EnumLocaleLinks.links_bind_device
    }
    //   用户需要完善个人信息
    if (this.UserStore.onneedCompleteInfo()) {
      // this.onCancel()
      this.UserStore.onToggleModal(this.$EnumLocaleLinks.links_complete)
      // this.UserStore.onSetToPaymentQuery(query)
      throw this.$EnumLocaleLinks.links_complete
    }
  }
  /**
   * 是否有待支付订单
   * **/
  //  VisibleHasPay = false;

  /**
   * 待支付订单详细信息
   * **/
  // hasPayInfo = {
  //   groupMemberHeaderUrl:"",//拼团人头像
  //   group:"",//是否拼团
  //   groupNo:"",//拼团的号
  //   orderNo: "",//订单编号
  //   payAmount: "",//支付的金额
  //   score: 0,//桐板数
  //   status: "",//
  //   buyWay:"",
  //   virtualCurrency: 0 //桐板儿优惠金额
  // }
  /**
   * 重新下单
   * **/
  // async placeOrder(){
  //   //先取消原订单
  //   let orderNo  = this.hasPayInfo.orderNo;
  //   let res = await this.$ajax.put(EnumApiOrder.OrderCancel,{orderNo});
  //   //如果取消成功,则弹出来协议
  //   if(res==true){
  //     this.VisibleHasPay = false;//隐藏掉重新下单
  //     this.VisibleAgreement = true;
  //   }
  // }
  // /**
  //  * 继续支付
  //  * **/
  // rePayOrder(){
  //   this.$router.push({
  //     name: "payment",
  //     query: {
  //       productId: lodash.get(this.dataSource, "id").toString(),
  //       orderNo:this.hasPayInfo.orderNo,
  //       give:this.hasPayInfo.buyWay
  //     },
  //   })
  // }

  onCancel() {
    this.VisibleAgreement = false
  }
  onBindHasPayCancel() {
    this.VisibleHasPay = false
  }
  created() {}
  mounted() {}
  updated() {}
  destroyed() {}
}
