/*
 * @Author: Erlin
 * @CreateTime: 2020-08-28 17:37:48
 * @LastEditors: Erlin
 * @LastEditTime: 2020-09-06 11:15:06
 * @Description: 我的
 */
import { BindAll } from 'lodash-decorators'
import { EnumApiMy } from '../../api'
import { AjaxBasics } from '../../helpers/ajaxBasics'
import MySunWork from './mySunWork'
import MyWork from './myWork'
import BishanWork from './bishanWork'
import MyBishan from './myBiShan'
import MyCoin from './myCoin'
import { EnumProductTypeQuery } from '@xt/client/enums'

@BindAll()
export class ControllerMy {
  constructor(protected $ajax: AjaxBasics) {}
  /**
   * 晒作业
   * @memberof ControllerMy
   */
  MyWork = new MyWork(this.$ajax)

  /**
   * 我晒出的作业
   * @memberof ControllerMy
   */
  MySunWork = new MySunWork(this.$ajax)

  /**
   * 笔山相关
   */
  BishanWork = new BishanWork(this.$ajax)

  /**
   * 我的积分页面，笔山明细 @mengxd
   * **/
  MyBishan = new MyBishan(this.$ajax)

  MyCoin = new MyCoin(this.$ajax)

  /**
   * 获取我的课程列表
   */
  async onGetMyProductList() {
    const res: any = await this.$ajax.get(EnumApiMy.MyCourseList)

    const progressingCourse = []
    let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    if (res?.courseResultVOS) {
      ;(<any[]>res.courseResultVOS).forEach(item => {
        if (currentTime < item.learnStartTime || (currentTime >= item.learnStartTime && currentTime < item.learnEndTime)) {
          progressingCourse.push({ ...item, type: EnumProductTypeQuery.Course })
        }
      })
    }
    if (res?.activityResultVOS) {
      ;(<any[]>res.courseResultVOS).forEach(item => {
        if (currentTime < item.learnStartTime || (currentTime >= item.learnStartTime && currentTime < item.learnEndTime)) {
          progressingCourse.push({ ...item, type: EnumProductTypeQuery.DayCourse })
        }
      })
    }
    if (res?.showResultVOS) {
      ;(<any[]>res.showResultVOS).forEach(item => {
        if (currentTime < item.voteEndTime) {
          progressingCourse.push({ ...item, type: EnumProductTypeQuery.Exhibition })
        }
      })
    }
    if (res?.selfStudyResultVOS) {
      ;(<any[]>res.selfStudyResultVOS).forEach(item => {
        if (currentTime < item.learnStartTime || (currentTime >= item.learnStartTime && currentTime < item.learnEndTime)) {
          progressingCourse.push({ ...item, type: EnumProductTypeQuery.SelfStudy })
        }
      })
    }

    return { ...res, progressingResultVOS: progressingCourse }
  }

  /**
   * 获取我的全部课程列表
   * @returns
   */
  async onGetMyAllProduct() {
    const res: Array<any> = await this.$ajax.get(EnumApiMy.MyAllCourse)
    return res
  }
}
export default ControllerMy
