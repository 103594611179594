/*
 * @Author: mengxd
 * @CreateTime: 2021-06-07 16:21:14
 * @Description: 去上课
 */
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { EnumProductTypeQuery } from '@xt/client/enums'
import { AjaxBasics } from '@xt/client'

type ActiveItemType = 'progressing' | 'course' | 'daycourse' | 'exhibition' | 'wenfang' | 'selfstudy'

@Observer
@Component
export class MixinsGoCourse extends Vue {
  myProductList = {
    courseResultVOS: [],
    activityResultVOS: [],
    showResultVOS: [],
    selfStudyResultVOS: [],
    progressingResultVOS: []
  }
  //进行中课程
  get progressingCourse() {
    return this.myProductList.progressingResultVOS
  }
  //直播课
  get liveCourse() {
    if (!this.myProductList.courseResultVOS) return []

    return this.myProductList.courseResultVOS
  }
  //日课
  get dayCourse() {
    if (!this.myProductList.activityResultVOS) return []

    return this.myProductList.activityResultVOS
  }
  // 自习课
  get selfStudyCourse() {
    if (!this.myProductList.selfStudyResultVOS) return []

    return this.myProductList.selfStudyResultVOS
  }
  //展览辅导课
  get exhibitionCourse() {
    if (!this.myProductList.showResultVOS) return []

    return this.myProductList.showResultVOS
  }

  courseList = []
  activeType: ActiveItemType = 'progressing'
  changeTabHandler(activeItem: { id: number; title: string; type: ActiveItemType }) {
    this.activeType = activeItem.type
    switch (activeItem.type) {
      case 'course':
        this.courseList = this.liveCourse
        break
      case 'daycourse':
        this.courseList = this.dayCourse
        break
      case 'exhibition':
        this.courseList = this.exhibitionCourse
        break
      case 'selfstudy':
        this.courseList = this.selfStudyCourse
        break
      case 'progressing':
        this.courseList = this.myProductList.progressingResultVOS
        break
    }
  }

  mounted() {}
  updated() {}
  destroyed() {
    //this.Pagination.onReset()
  }
}
