/**
 * @author 冷 (https://github.com/LengYXin)
 * @email lengyingxin8966@gmail.com
 * @create date 2020-08-05 14:13:07
 * @modify date 2020-08-05 14:13:07
 * @desc [description]
 */
import { BindAll } from 'lodash-decorators'
import lodash from 'lodash'
import Bowser from 'bowser'
import { action, observable } from 'mobx'

/**
 * 接口请求中携带的appid，后端生成的
 */
const XTAppid = {
  H5: {
    prdXTAppid: '3263a8c4c8c024fe',
    devXTAppid: '67e244f1d94823d3',
    testXTAppid: '00bbdbe98094cd2e'
  },

  PC: {
    prdXTAppid: '2fc02c05c3c782d4',
    devXTAppid: '5ccf6138c33448be',
    testXTAppid: '30402b3b85afd431'
  }
}

// console.dir( Bowser)
@BindAll()
export class XTGlobal {
  constructor() {
    try {
      let parsed = null
      const args = JSON.parse(decodeURIComponent(location.hash.slice(1)) || '{}')
      // source参数，记录来源，被哪个小程序打开
      if (args.s) {
        parsed = args.s
        sessionStorage.setItem('__xt__embedded__source', args.s)
      }

      switch (parsed) {
        case 'show':
          this.embeddedProgrem = 'Show'
          break
        case 'uc':
          this.embeddedProgrem = 'UserCenter'
          break
        default:
          throw new Error(`unhandled source: ${parsed}`)
      }
    } catch (err) {
      // console.warn("XTGlobal constructor error", err);
    }
  }

  @observable embeddedProgrem: 'UserCenter' | 'Show' | null = null

  @observable upUpdateTime: number = Date.now()

  @action setUpdateTime(upUpdateTime: number) {
    this.upUpdateTime = upUpdateTime
  }

  Bowser = Bowser
  /**
   * 平台 配置
   * @memberof XTGlobal
   */
  platform: 'PC' | 'Mobile' = process.env.platform as any
  /**
   * 设备指纹
   */
  devId: string | null = null
  /**
   * 微信 appid
   * @memberof XTGlobal
   */
  appid = lodash.get(window, '__xt__env.appid', process.env.appid)
  /**
   * 开放平台 appid
   * @memberof XTGlobal
   */
  openAppid = lodash.get(window, '__xt__env.openAppid', process.env.openAppid)
  /**
   * 阿里云 日志 pid
   * @memberof XTGlobal
   */
  logger = lodash.get(window, '__xt__env.logger', process.env.logger)
  /**
   * domain 域名 根目录
   * @memberof XTGlobal
   */
  base = lodash.get(window, '__xt__env.base', process.env.base)
  /**
   * 静态资源地址 使用的时候 @Static/**.*
   * @memberof XTGlobal
   */
  static = lodash.get(window, '__xt__env.static', process.env.static)
  /**
   * domain 域名
   * @memberof XTGlobal
   */
  domain = lodash.get(window, '__xt__env.domain', process.env.domain)
  /**
   * api 地址
   * @memberof XTGlobal
   */

  target = lodash.get(window, '__xt__env.target', process.env.target)
  /**
   * 环境设备信息
   * @memberof XTGlobal
   */
  userAgent = Bowser.parse(window.navigator.userAgent)
  /**
   *   localStorage  前缀
   * @memberof XTGlobal
   */
  localStorageStartsWith = '__xt_'
  /**
   * 版本信息
   * @memberof XTGlobal
   */
  version = lodash.get(window, '__xt__env.version', process.env.version)
  /**
   * 构建时间戳
   * @memberof XTGlobal
   */
  timestamp = lodash.get(window, '__xt__env.timestamp', process.env.timestamp)
  /**
   * Node env
   * @memberof XTGlobal
   */
  NODE_ENV: typeof process.env.NODE_ENV = lodash.get(window, '__xt__env.NODE_ENV', process.env.NODE_ENV)
  /**
   * 环境
   * @memberof XTGlobal
   */
  DEPLOY_ENV: typeof process.env.DEPLOY_ENV = lodash.get(window, '__xt__env.DEPLOY_ENV', process.env.DEPLOY_ENV)
  /**
   * 文件地址
   * @memberof XTGlobal
   */
  fileTarget = lodash.get(window, '__xt__env.fileTarget', process.env.fileTarget)
  /**
   * Android
   * @readonly
   * @memberof XTGlobal
   */
  isAndroid = lodash.eq(this.userAgent.os.name, Bowser.OS_MAP.Android)
  /**
   * iOS
   * @readonly
   * @memberof XTGlobal
   */
  isiOS = lodash.eq(this.userAgent.os.name, Bowser.OS_MAP.iOS)
  /**
   * Windows
   * @readonly
   * @memberof XTGlobal
   */
  isWindows = lodash.eq(this.userAgent.os.name, Bowser.OS_MAP.Windows)
  /**
   * Mac
   * @readonly
   * @memberof XTGlobal
   */
  isMac = lodash.eq(this.userAgent.os.name, Bowser.OS_MAP.MacOS)
  /**
   * 桌面
   * @readonly
   * @memberof XTGlobal
   */
  isDesktop = lodash.eq(this.userAgent.platform.type, Bowser.PLATFORMS_MAP.desktop)
  /**
   * 手机
   * @readonly
   * @memberof XTGlobal
   */
  isMobile = lodash.eq(this.userAgent.platform.type, Bowser.PLATFORMS_MAP.mobile)
  /**
   * 平板电脑
   * @readonly
   * @memberof XTGlobal
   */
  isTablet = lodash.eq(this.userAgent.platform.type, Bowser.PLATFORMS_MAP.tablet)
  /**
   * 今天是否有直播
   */
  isLive: boolean = false
  /**
   * 小程序内嵌h5  ios是否展示价格  默认展示
   */
  @observable isPaymentShowPrice: boolean = true

  @action setPaymentShowPrice(show: boolean) {
    this.isPaymentShowPrice = show
  }

  /**
   * 符合 手机端页面
   * @readonly
   * @memberof XTGlobal
   */
  get isMobilePage() {
    if (this.isEmbeddedH5Page) {
      return true
    }
    if (this.isMobile) {
      return true
    }
    if (this.isTablet) {
      return window.innerWidth < window.innerHeight
    }
    return false
  }
  /**
   * 本地 dev
   * @memberof XTGlobal
   */
  get dev() {
    return this.NODE_ENV === 'development'
  }
  /**
   *生产环境
   * @memberof XTGlobal
   */
  get production() {
    return this.DEPLOY_ENV === 'pro'
  }

  get xtAppid() {
    return this.platform === 'Mobile' ? this.getMobileXtAppid() : this.getPCXtAppid()
  }

  getMobileXtAppid() {
    return this.DEPLOY_ENV === 'dev' ? XTAppid.H5.testXTAppid : this.production ? XTAppid.H5.prdXTAppid : XTAppid.H5.testXTAppid
  }
  getPCXtAppid() {
    return this.DEPLOY_ENV === 'dev' ? XTAppid.PC.testXTAppid : this.production ? XTAppid.PC.prdXTAppid : XTAppid.PC.testXTAppid
  }
  /**
   * 微信浏览器
   * @readonly
   * @memberof XTGlobal
   */
  get WechatBowser() {
    return lodash.eq(this.userAgent.browser.name, Bowser.BROWSER_MAP.wechat)
  }
  /**
   * Safari
   * @readonly
   * @memberof XTGlobal
   */
  get SafariBowser() {
    return lodash.eq(this.userAgent.browser.name, Bowser.BROWSER_MAP.safari)
  }
  /**
   * 检查版本信息
   */
  onInspectVersion() {
    if (window && window.localStorage) {
      const version = window.localStorage.getItem('version')
      // 清理 版本 不统一缓存
      if (!lodash.eq(version, this.version)) {
        lodash.mapKeys(window.localStorage, (value, key: any) => {
          if (lodash.startsWith(key, this.localStorageStartsWith)) {
            window.localStorage.removeItem(key)
          }
        })
      }
      window.localStorage.setItem('version', this.version)
    }
  }

  /**
   * 是否是小程序内嵌的h5页面
   */
  get isEmbeddedH5Page() {
    const userAgent = navigator.userAgent.toLowerCase()
    return userAgent.includes('miniprogram')
  }

  // get embeddedProgrem(): "UserCenter" | "Show" | null {
  //   if (!this.isEmbeddedH5Page) return null;

  //   return "UserCenter";
  // }

  isReady = false
}
export default new XTGlobal()
