import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { AjaxBasics } from '@xt/client/helpers'
import moment from 'moment'
import OrderItemScript from './order'

@Observer
@Component
export default class GroupBuyScript extends OrderItemScript {
  showDownTime: boolean = false
  endTime: number
  h: string = '00'
  m: string = '00'
  s: string = '00'
  downtimeTimer: NodeJS.Timer

  get collageHand() {
    return this.dataSource.origin.firstGroupMemberHeaderUrl && this.dataSource.origin.otherGroupMemberHeaderUrl
      ? this.$store.$storeGroup.collageHandSuccessUrl
      : this.$store.$storeGroup.collageHandUrl
  }
  get firstHeaderImg() {
    // return this.myCreate ? this.$store.$storeUser.UserInfo.headerUrl : this.dataSource.origin.firstGroupMemberHeaderUrl
    return this.myCreate ? this.$store.$storeUser.UserInfo.id : this.firstGroupMemberId
  }

  get otherHeaderImg() {
    // return this.myCreate ? this.dataSource.origin.otherGroupMemberHeaderUrl : this.$store.$storeUser.UserInfo.headerUrl
    return this.myCreate
      ? this.dataSource.origin.otherGroupMemberId
        ? this.dataSource.origin.otherGroupMemberId
        : ''
      : this.$store.$storeUser.UserInfo.id
  }

  get firstGroupMemberId() {
    return this.dataSource.origin.firstGroupMemberId || this.$store.$storeUser.UserInfo.id
  }

  get myCreate() {
    return this.firstGroupMemberId === this.$store.$storeUser.UserInfo.id
  }

  /**
   * 倒计时的实现
   */
  downtime() {
    this.downtimeTimer = setInterval(() => {
      let currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
      //拼团结束时间提前24小时结束
      let diff = this.endTime - 24 * 1000 * 60 * 60 - currentTime
      // Thu Jan 01 1970 08:00:00 GMT+0800 (中国标准时间) {} 所以 - 28800000
      const [h, m, s] =
        diff <= 0
          ? ['00', '00', '00']
          : moment(diff - 28800000)
              .format('HH:mm:ss')
              .split(':')
      this.h = h
      this.m = m
      this.s = s
      if (diff <= 0) {
        clearInterval(this.downtimeTimer)
      }
    }, 200)
  }
  /**
   * 销毁前清理
   */
  beforeDestroy() {
    clearInterval(this.downtimeTimer)
  }
  mounted() {
    let dayTime = 24 * 1000 * 60 * 60 // 一天多少毫秒
    let curtime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
    // 拼团的创建时间加24个小时计算结束时间
    this.endTime = this.dataSource.origin.groupCreateTime + dayTime * 2
    /**
     * 因为报名结束后仍有团处于等待成团状态
     * 这种情况下拼团倒计时根据报名结束时间来计算
     */
    if (this.endTime > this.dataSource.origin.signUpEndTime) this.endTime = this.dataSource.origin.signUpEndTime
    if (this.dataSource.origin.status === 0 && this.myCreate && !this.dataSource.isCancelled) {
      this.showDownTime = true
      this.downtime()
    }
  }

  toGroupDetail() {
    if (this.dataSource.isNotPay) return this.$msg('请先完成支付') //只要是未支付，都不让跳
    if (this.dataSource.isCancelled) return this.$msg('订单已取消')
    this.$router.push({
      name: 'groupbuy-groupNo',
      params: {
        groupNo: this.dataSource.origin.groupNo,
      }
    })
  }
}
