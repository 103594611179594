import { AccountAlertInfo } from '@xt/client/entities/user/types.d'
import { StorageWithExpiredTime } from '@xt/client/helpers/storage'
import { BanType, StorageKeys } from '@xt/client/enums'

export function parseAccountBanInfo(banInfo: AccountAlertInfo): null | AccountAlertInfo {
  if (!banInfo || !banInfo.id) return null

  const key = StorageKeys.AccountAlertModal
  const storage = new StorageWithExpiredTime(localStorage)
  const value = storage.get<number>(key)

  if (value === banInfo.id && banInfo.banType === BanType.Warn) {
    // 已经弹过并且还没有到下一次弹出的时候
    return null
  }

  return banInfo
}
