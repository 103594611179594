/**
 * @author wangsl
 */
import lodash from 'lodash'
import { EnumVerifyState } from '@xt/client/entities'
import { AjaxBasics } from '@xt/client/helpers'

/********************************************* 枚举定义  ****************************************** */

export type AuthQuery = {
  state: string
  code: any
}

/**
 * 微信验证能力的枚举类
 */
export enum WeiXinAuth {
  Login = 'login', // 执行登录流程
  Bind = 'bind', // 执行绑定流程
  OpenId = 'openid', // 执行获取Opneid流程
  ActiveVerify = 'active_verify', // 主动校验绑定微信是否一致
  PassiveVerify = 'passive_verify' // 被动校验绑定微信是否一致
}

/**
 * 微信登陆方式的枚举
 */
export enum WeiXinLoginMethod {
  // 微信开放平台，用于PC端微信登陆
  Open = 'wxopen_code',
  // 微信公众平台，用户Mobile端微信登陆
  Mp = 'wxmp_code'
}

/********************************************* 数据类型定义  ****************************************** */

type Process = WeiXinAuth.Bind | WeiXinAuth.Login | WeiXinAuth.OpenId | WeiXinAuth.ActiveVerify | WeiXinAuth.PassiveVerify

type ProcessFilter = {
  readonly routerName: string
  readonly ignoreProcess: Process[]
}

/********************************************* 页面过滤定义  ****************************************** */

/**
 * 通过Router Name过滤
 *
 * 应对需要单独处理某些（个）流程的特殊情况
 */
const ProcessFilters: ProcessFilter[] = [
  {
    routerName: 'groupbuy-groupNo',
    ignoreProcess: [WeiXinAuth.Bind, WeiXinAuth.Login, WeiXinAuth.ActiveVerify, WeiXinAuth.PassiveVerify]
  }
]

/********************************************* 封装类实现  ****************************************** */

/**
 * 封装了微信鉴权相关功能
 * 通过路由参数的state区分能力
 */
export default class WX {
  static _instance: WX = new WX()
  private codesStorageKey = 'old_codes'
  // 记录历史code
  private _oldCodes: string[] = []

  /**
   * 单例模式方便全局调用
   */
  constructor() {
    const backup = sessionStorage.getItem(this.codesStorageKey)
    if (backup) {
      this._oldCodes = backup.split(',')
      console.log('初始化了_oldCodes', this._oldCodes)
    }

    return WX._instance
  }

  private _appendCodeStorage(value: string) {
    /**
     * code1,code2,code3
     */
    const old = sessionStorage.getItem(this.codesStorageKey)
    if (!old) {
      sessionStorage.setItem(this.codesStorageKey, value)
    } else {
      sessionStorage.setItem(this.codesStorageKey, `${old},${value}`)
    }
  }

  /**
   * 添加历史记录
   */
  private _addOldCode(code: string) {
    this._appendCodeStorage(code)
    this._oldCodes.push(code)
  }

  /**
   * 是否在历史记录中
   */
  private _containCode(code: string) {
    return this._oldCodes.indexOf(code) !== -1
  }

  private _isPc(): boolean {
    return window.$nuxt.$store.$global.platform === 'PC'
  }

  /**
   * 验证对应的query参数
   */
  private _getAuthQuery(): AuthQuery {
    return {
      code: <string>window.$nuxt.$route.query.code,
      state: <string>window.$nuxt.$route.query.state // 可以选择处理为json字符串
    }
  }

  /**
   * 校验是否需要过滤指定流程
   */
  private _filter(process: Process): boolean {
    for (let i = 0; i < ProcessFilters.length; i++) {
      let item = ProcessFilters[i]
      if (window.$nuxt.$route.name === item.routerName && item.ignoreProcess.indexOf(process) !== -1) return true
    }
    return false
  }

  /**
   * 重定向地址
   */
  private _redirectUri(): string {
    return (
      window.location.origin +
      window.$nuxt.$router.resolve({
        name: window.$nuxt.$route.name,
        params: window.$nuxt.$route.params,
        query: window.$nuxt.$route.query
      }).href
    )
  }

  /**
   * 切换路由
   */
  private _replceRoute(callback: () => void): any {
    let query: any = lodash.omit(window.$nuxt.$route.query, ['state', 'code'])
    window.$nuxt.$router.replace({ name: window.$nuxt.$route.name, params: window.$nuxt.$route.params, query }, callback.bind(this))
  }

  /**
   * 区分平台执行微信绑定流程
   *
   * 单独对路由参数进行校验，以此实现该函数可独立完成微信绑定的完整功能
   */
  async wxBind(needBind?: () => void, bindSuccess?: () => void, bindFail?: () => void): Promise<boolean> {
    const { code, state } = this._getAuthQuery()
    // alert("wxBind:" + code + this._containCode(code).toString())
    if (code && state === WeiXinAuth.Bind && !this._containCode(code)) {
      this._addOldCode(code)
      needBind && needBind()
      // 微信授权
      // 清空code信息，防止刷新后重复提交
      return await new Promise(r => {
        this._replceRoute(async () => {
          const storeUser = window.$nuxt.$store.$storeUser
          try {
            let res = await storeUser.onBindwechat({ code, wxPlatformType: this._isPc() ? 1 : 2 })
            if (res === true) {
              storeUser.onUpdatingUserInfo()
              bindSuccess ? bindSuccess() : window.$nuxt.$msg('绑定成功', 'success')
            }
          } catch (_) {
            bindFail && bindFail() //: window.$nuxt.$msg("网络开小差了")
          }
          r(true)
        })
      })
    }
    return false
  }

  /**
   * 区分平台执行微信登陆流程
   *
   * 单独对路由参数进行校验，以此实现该函数可独立完成微信登陆的完整功能
   */
  async wxLogin(needLogin?: () => void, loginSuccess?: () => void, loginFail?: () => void): Promise<boolean> {
    const { code, state } = this._getAuthQuery()
    // alert("wxLogin:" + code + this._containCode(code).toString())
    if (code && state === WeiXinAuth.Login && !this._containCode(code)) {
      this._addOldCode(code)
      needLogin && needLogin()
      return await new Promise(async r => {
        // 微信登录进来的
        const storeUser = window.$nuxt.$store.$storeUser
        // 清空code信息，防止刷新后重复提交
        this._replceRoute(async () => {
          try {
            // await storeUser.onLogin({ code }, this._isPc() ? WeiXinLoginMethod.Open : WeiXinLoginMethod.Mp);
            // if (storeUser.UserInfo.id) loginSuccess ? loginSuccess() : window.$nuxt.$msg("登录成功", "success")
            // // 未绑定手机或邮箱
            // if (storeUser.needBindDevice) {
            //   this._isPc()
            //   ? storeUser.onToggleModal(window.$nuxt.$EnumLocaleLinks.links_bind_device)
            //   :  window.$nuxt.$router.push({ name: "other-bind" })
            // }

            // DEAD CODE ?
            if (this._isPc()) {
              await storeUser.onLogin({ code }, WeiXinLoginMethod.Open)
              if (storeUser.UserInfo.id) loginSuccess ? loginSuccess() : window.$nuxt.$msg('登录成功，欢迎你桐学 🎉', 'success')

              // 未绑定手机或邮箱
              if (storeUser.needBindDevice) {
                storeUser.onToggleModal(window.$nuxt.$EnumLocaleLinks.links_bind_device)
              }
            } else {
              // H5
              const resp = await storeUser.code4Judge(code)
              if (resp && !resp.emailExist && !resp.phoneExist) {
                // 手机邮箱都不存在，去绑定
                // 禁止登录弹窗打开
                // storeUser.onToggleVisible(false);
                window.$nuxt.$router.push({ name: 'other-bind' })
              } else {
                // 直接登录成功
                // await storeUser.onLogin({ code }, WeiXinLoginMethod.Mp);
                await storeUser.onLogin(
                  {
                    tempToken: resp.tempToken,
                    code: ''
                  },
                  'wx_applets2'
                )
                if (storeUser.UserInfo.id) {
                  loginSuccess ? loginSuccess() : window.$nuxt.$msg('登录成功，欢迎你桐学 🎉', 'success')
                }
              }
            }
          } catch (_) {
            loginFail && loginFail()
          }
          r(true)
        })
      })
    }
    return false
  }

  /**
   * 执行获取Openid流程
   *
   * 单独对路由参数进行校验，以此实现该函数可独立完成获取Openid的完整功能
   */
  async wxOpenid(needOpenid?: () => void, openidSuccess?: () => void, openidFail?: () => void): Promise<boolean> {
    const { code, state } = this._getAuthQuery()
    // alert("wxOpenid:" + code + this._containCode(code).toString())
    if (code && state === WeiXinAuth.OpenId && !this._containCode(code)) {
      this._addOldCode(code)
      needOpenid && needOpenid()
      return await new Promise(r => {
        // 清空code信息，防止刷新后重复提交
        this._replceRoute(async () => {
          try {
            const openid = await window.$nuxt.$store.$wechat.onGetOpenid(code)
            if (openid) window.$nuxt.$store.$storeUser.setWXOpenid(openid)
            openidSuccess && openidSuccess()
          } catch (_) {
            openidFail && openidFail()
          }
          r(true)
        })
      })
    }
    return false
  }

  /**
   * 校验微信绑定账户是否一致
   * @param needBind
   * @param bindSuccess
   * @param bindFail
   * @returns
   */
  async wxVerify(needVerify?: () => void, verifySuccess?: () => void, verifyFail?: () => void): Promise<boolean> {
    const { code, state } = this._getAuthQuery()
    if (code && (state === WeiXinAuth.ActiveVerify || state === WeiXinAuth.PassiveVerify) && !this._containCode(code)) {
      this._addOldCode(code)
      needVerify && needVerify()
      // 微信授权
      // 清空code信息，防止刷新后重复提交
      return await new Promise(r => {
        this._replceRoute(async () => {
          const storeUser = window.$nuxt.$store.$storeUser
          try {
            const res = await storeUser.onCheckBind({ code, memberId: storeUser.UserInfo.id })
            if (res) {
              await storeUser.onUpdatingUserInfo()
            }
            storeUser.showWechatVerify({
              title: state === WeiXinAuth.ActiveVerify ? '微信绑定' : '微信验证',
              content:
                state === WeiXinAuth.ActiveVerify
                  ? '点击验证绑定微信与使用微信是否一致。'
                  : '教练日课课程在暄桐日课小程序中进行，需使用微信登录学习。购买日课后，不支持微信换绑和解绑。',
              type: state === WeiXinAuth.ActiveVerify ? 'unbind' : 'verify',
              tip: state === WeiXinAuth.ActiveVerify ? '' : '点击验证绑定微信与使用微信是否一致',
              status: res ? EnumVerifyState.PassVerify : EnumVerifyState.FailVerify,
              signupHandler: () => {
                // tip：经过刷新后  流程就断掉了
                if (!res) {
                  // 绑定微信不一致  点击继续报名  做个假数据让用户可以正常报名  不再走校验弹窗  否则会陷入死循环
                  const currentTime = AjaxBasics.serviceDate.utcOffset(+8).valueOf()
                  storeUser.setWXCheckTime(currentTime)
                }
                storeUser.hideWechatVerify()
              }
            })
            verifySuccess && verifySuccess()
          } catch (_) {
            verifyFail && verifyFail() //: window.$nuxt.$msg("网络开小差了")
          }
          r(true)
        })
      })
    }
    return false
  }

  /**
   * 微信校验的公共处理逻辑，根据State参数执行不同的流程
   */
  async wxAuth(need?: () => void, success?: () => void, fail?: () => void): Promise<boolean> {
    const { code, state } = this._getAuthQuery()
    // alert("auth:" + code + this._containCode(code).toString())
    if (!code) return false // 不存在Code则无需处理
    switch (state) {
      // 执行微信登陆流程
      case WeiXinAuth.Login:
        return !this._filter(WeiXinAuth.Login) && (await this.wxLogin(need, success, fail))
      // 执行微信绑定流程
      case WeiXinAuth.Bind:
        return !this._filter(WeiXinAuth.Bind) && (await this.wxBind(need, success, fail))
      // 执行获取Openid功能
      case WeiXinAuth.OpenId:
        return !this._filter(WeiXinAuth.OpenId) && (await this.wxOpenid(need, success, fail))
      case WeiXinAuth.ActiveVerify:
      case WeiXinAuth.PassiveVerify:
        return !this._filter(state) && (await this.wxVerify(need, success, fail))
      default:
        return false
    }
  }

  static async wxAuth(need?: () => void, success?: () => void, fail?: () => void): Promise<boolean> {
    return new WX().wxAuth(need, success, fail)
  }
  static async wxLogin(needLogin?: () => void, loginSuccess?: () => void, loginFail?: () => void): Promise<boolean> {
    return new WX().wxLogin(needLogin, loginSuccess, loginFail)
  }
  static async wxBind(needBind?: () => void, bindSuccess?: () => void, bindFail?: () => void): Promise<boolean> {
    return new WX().wxBind(needBind, bindSuccess, bindFail)
  }
  static async wxOpenid(needOpenid?: () => void, openidSuccess?: () => void, openidFail?: () => void): Promise<boolean> {
    return new WX().wxOpenid(needOpenid, openidSuccess, openidFail)
  }
  static async addOldCode(code) {
    return new WX()._addOldCode(code)
  }
}
