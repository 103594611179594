import { EnumProductType } from '@xt/client/enums'
import { Observer } from 'mobx-vue'
import { Vue, Component, Prop } from 'vue-property-decorator'
import Product from '../../entities/basics/product'
import { EnumWxLink } from '@xt/client/enums'

@Observer
@Component
export default class ProductScript extends Vue {
  @Prop({}) dataSource: Product
  @Prop({}) isShowRefundEndTime: any
  @Prop({}) refundEndTime: any
  @Prop() refundStatus: any

  // 是否在微信内
  get isWechatBowser() {
    return this.$store.$global.WechatBowser
  }

  get isMobile() {
    return this.$store.$global.isMobile
  }

  get ExhibitionLink() {
    return EnumWxLink.XTExhibitionLink
  }

  get cover() {
    let width = this.isMobile ? 160 : 284
    let height = this.isMobile ? 160 : 174
    let imgUrl = this.isMobile ? this.dataSource.origin.coverUrlH5 : this.dataSource.origin.coverUrl
    return `${imgUrl}?x-oss-process=image/resize,w_${width * 2},h_${height * 2},m_fill/quality,q_90`
  }

  /**
   * 是否展示团购按钮
   */
  get isGroup() {
    return this.dataSource.isOpenGroup && this.dataSource.isEnrolling
  }

  toLiveCourseDetail(): void {
    if (this.dataSource.origin.activityPageUrl) this.$router.push({ path: this.dataSource.origin.activityPageUrl })
    else
      this.$router.push({
        path: `/commodity/${this.dataSource.origin.id.toString()}`
      })
  }
  toExhibitionDetail(): void {
    // if (!this.isWechatBowser) return this.$msg("请在微信内操作")
    // window.location.href = EnumWxLink.XTExhibitionLink;
  }

  toDayCourseDetail(): void {
    this.$router.push({
      path: `/commodity/${this.dataSource.origin.id.toString()}`
    })
  }

  toProductDetail(): void {
    switch (this.dataSource.origin.productType) {
      case EnumProductType.dayCourse:
        return this.toDayCourseDetail()
      case EnumProductType.fullstage:
        return this.toLiveCourseDetail()
      case EnumProductType.single:
        return this.toLiveCourseDetail()
      case EnumProductType.offlineExhibition:
        return this.toExhibitionDetail()
      case EnumProductType.onlineExhibition:
        return this.toExhibitionDetail()
      default:
      // 异常
    }
  }

  /**
   * 当前是否是展览课
   */
  isExhibitionCourse(): boolean {
    return [EnumProductType.offlineExhibition, EnumProductType.onlineExhibition].includes(this.dataSource.origin.productType)
  }
  created() {}
}
